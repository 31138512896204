import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Navbar from "../components/Navbar";
import Card from "../components/Card";
import info_icon from "../assets/info_icon.svg";

import Button from "../ui/src/components/Button/Button";
import { H2, H5 } from "../ui/src/components/Typography";

import "../styles/review.scss";
import { BILLING, INSURANCE } from "../constants";

const Review = (props) => {
  const location = useLocation();

  const [step, setStep] = useState(INSURANCE);

  const copy = {
    INSURANCE: {
      title: "Insurance and Payments",
      content:
        "<H4 bold={true}>When looking at your records, we noticed you had a job change within the last year.</H4><H4 bold={true}>Due to this change, please review your current insurance and update to a new card if neccesary.</H4>",
    },
    BILLING: {
      title: "Billing",
      content:
        "<H4 bold={true}>You have a copay, prepayment, and prior balance due. Select the items you are able to pay.</H4>",
    },
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params?.get("process") || INSURANCE;
    setStep(status.toUpperCase());
  }, [location.search]);

  return (
    <>
      <div className="review-page">
        <div className="header-section">
          <H2>{copy[step].title}</H2>
          <div
            className="body"
            dangerouslySetInnerHTML={{ __html: copy[step].content }}
          />
        </div>
        {step === INSURANCE && (
          <>
            <Card>Insurance Card Placeholder</Card>
            <Button>Add new insurance</Button>
            <Link to="/review?process=billing">
              <Button variant="outlined">This is still my insurance</Button>
            </Link>
          </>
        )}
        {step === BILLING && (
          <>
            <Card>Billing UI placeholder</Card>
            <Card>
              <div className="image-card">
                <img src={info_icon} alt="card-icon" />
                <H5>
                  Full payment is not required at this time but will save you
                  time when you arrive.
                </H5>
              </div>
            </Card>

            <Link to="/home?status=registered">
              <Button>Continue</Button>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default Review;
