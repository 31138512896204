import React, { useState } from "react";
import "../../styles/detail-modal.scss";
import { H4, H5, H6 } from "../../ui/src/components/Typography";
import qrSample from "../../assets/qr_sample.svg";
import DetailSection from "../DetailSection";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";

//data
import { sectionContentMap } from "../../content";

import {
  PATIENT_DETAILS,
  CLINICAL_HISTORY,
  INSURANCE_BILLING,
  DOCUMENTS,
} from "../../constants";

// pull this out to theme
const primaryColor = "#111";
const DetailModalContent = (props) => {
  const [activeSection, setActiveSection] = useState(0);

  const sectionList = [
    PATIENT_DETAILS,
    CLINICAL_HISTORY,
    INSURANCE_BILLING,
    DOCUMENTS,
  ];

  const handleClick = (val) => {
    //handle ends
    if (val === 1 && activeSection === 3) {
      return;
    }
    if (val === -1 && activeSection === 0) {
      return;
    }
    setActiveSection(activeSection + val);
  };

  const sectionContent = sectionContentMap[sectionList[activeSection]];

  const registrationDetails = props.details;

  return (
    <div className="detail-modal">
      <H4 bold={true}>
        You are registered for your appointment. Show this code at the kiosk for
        when you arrive to check-in.
      </H4>
      <div className="card qr-card">
        <img src={qrSample} alt="qr code" />
        <H6 color="#8E8E8E">Check-in code: a33x-23xx-bd76-l337</H6>
      </div>
      <H4>
        Below are the results for your patient registration. Please review the
        details and update anything that seems out-of-date.
      </H4>
      <div className="button-row">
        <H4 bold={true}>Page {activeSection + 1} of 4</H4>
        <div>
          <Arrow
            stroke={activeSection === 0 ? "#C4C4C4" : primaryColor}
            className="arrow arrow-left"
            onClick={() => handleClick(-1)}
          />
          <Arrow
            stroke={activeSection === 3 ? "#C4C4C4" : primaryColor}
            className="arrow arrow-right"
            onClick={() => handleClick(1)}
          />
        </div>
      </div>

      <div className="card">
        <H4 bold={true} className="section-header">
          {sectionContent.title}
        </H4>
        <H5>{sectionContent.subtitle}</H5>
        {registrationDetails &&
          registrationDetails[sectionList[activeSection]]?.map((section) => {
            return (
              <DetailSection
                loading={false}
                ready={true}
                header={section.header}
                fields={section.fields}
                hideRecommendations={true}
                // dismissRecommendation={() => dismissRecommendation(section, i)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default DetailModalContent;
