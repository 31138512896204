import React from "react";
import ReactModal from "react-modal";
import "./modal.scss";
import close from "../../assets/close.svg";
import { H4 } from "../Typography";

interface Props {
  /**
   * Children of component. Content of modal body
   */
  children: React.ReactNode;

  /**
   * Bold header text
   */
  title: string;

  /**
   * Optional custom class name
   */
  overlayClassName?: string;

  /**
   * Optional custom class name
   */
  modalClassName?: string;

  /**
   * Controlled state boolean passed to underlying Modal component to control rendering
   */
  modalIsOpen: boolean;

  /**
   * Handler function for modal dismissal. Passed to close button onClick event and to modal
   * close event. Should manipulate isOpen value
   */
  closeModal: () => unknown;
}

const Modal: React.FC<Props> = (props) => {
  const { title, modalIsOpen, closeModal } = props;

  // find a way to get this working
  const onAfterOpen = () => {
    // document.body.style.position = "fixed";
    // document.body.style.top = `-${window.scrollY}px`;
    document.body.style.overflow = "hidden";
  };

  const onAfterClose = () => {
    document.body.style.overflow = "auto";
    // const scrollY = document.body.style.top;
    // document.body.style.position = "";
    // document.body.style.top = "";
    // window.scrollTo(0, parseInt(scrollY || "0") * -1);
  };

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={props.modalClassName || "custom-modal"}
      overlayClassName={props.overlayClassName || "custom-overlay"}
      closeTimeoutMS={300}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
    >
      <div className="modal-wrapper">
        <div className="modal-header">
          <H4>{title}</H4>
          <button onClick={closeModal}>
            <img src={close} alt="close modal icon" />
          </button>
        </div>
        <div className="modal-body">{props.children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
