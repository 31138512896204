/**
 * Toasts serve the purpose of displaying feedback to the user when there has
 * been an update to a state on a page.
 *
 * Mobile toasts have a different style and placement from desktop toasts, so
 * help better support the container spacing on mobile views, and not risk
 * covering a pages call to action. They pop down from behind the top navigation
 * when presented, and return when cleared. They should be sticky with the top
 * navigation, which will ensure they are present when scrolling. They should
 * always be full width, and should auto-clear after 6000ms.
 *
 * Desktop toasts should be placed at the bottom right of the page, and pop out
 * from the right of the page. When cleared, they should clear out and to the
 * right of the page. At the end of their transition, they should be 32px from
 * the bottom right of the page.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1852%3A1827
 */

import React, { ForwardedRef, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { ReactComponent as CloseIcon } from "../../assets/x.svg";

import "./toast.scss";

export const AUTO_HIDE_DURATION = 3000;
const ANIMATION_DURATION = 500;

export type ToastVariant = "success" | "danger" | "info";

export interface Props {
  /**
   * The number of milliseconds to wait before automatically calling the onClose function.
   * Should be a minimum of 2000ms.
   * @default 6000
   */
  autoHideDuration?: number;

  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * The message to display.
   */
  message: string;

  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;

  /**
   * The toast variant to use.
   * @default "info"
   */
  variant?: ToastVariant;
}

const Toast = React.forwardRef(
  (
    {
      className,
      message,
      onClose,
      variant = "info",
      autoHideDuration = AUTO_HIDE_DURATION,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const classes = [`toast-${variant}`, className].filter(Boolean).join(" ");

    const [open, setOpen] = useState(false);

    const onCloseHandler = () => {
      setOpen(false);
      setTimeout(onClose, ANIMATION_DURATION);
    };

    // We need this to get the transition working properly...
    useEffect(() => {
      setOpen(true);
    }, []);

    useEffect(() => {
      const timeoutId = setTimeout(onCloseHandler, autoHideDuration);

      return () => clearTimeout(timeoutId);
    }, []);

    return (
      //@ts-ignore
      <CSSTransition
        in={open}
        timeout={ANIMATION_DURATION}
        unmountOnExit
        classNames={"toast"}
      >
        <div className={classes} ref={ref} role="alert">
          <span className="toast-message">{message}</span>
          <CloseIcon
            aria-label="Close toast"
            className="toast-icon"
            onClick={onCloseHandler}
            tabIndex={0}
          />
        </div>
      </CSSTransition>
    );
  }
);

export default Toast;
