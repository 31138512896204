import styled, { css, keyframes } from "styled-components";

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div.attrs(
  (props: { maxWidth?: number; maxHeight?: number }) => ({
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
  })
)`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: ${(props) => props.maxWidth && `${props.maxWidth}px`};
  max-height: ${(props) => props.maxHeight && `${props.maxHeight}px`};
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Video = styled.video`
  position: absolute;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 50px;
  right: 20px;
  bottom: 50px;
  left: 20px;
  box-shadow: 0 0 20px 56px rgba(0, 0, 0, 0.6);
  border: 2px dashed #ffffff;
  border-radius: 10px;
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${({ flash }: { flash: boolean }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;
