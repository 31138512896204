import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

import Modal from "../ui/src/components/Modal/Modal";
import { Toast } from "../ui/src/components/Toast";
import AppointmentCard from "../components/AppointmentCard";
import ScheduleModalContent from "../components/modals/ScheduleModalContent";
import ConfirmModalContent from "../components/modals/ConfirmModalContent";
import DetailModalContent from "../components/modals/DetailModalContent";
import RecommendationsModalContent from "../components/modals/RecommendationsModalContent";
import Card from "../components/Card";
import { useTheme } from "../Theme";

import info_icon from "../assets/info_icon.svg";
import Button from "../ui/src/components/Button/Button";
import avatar from "../assets/avatar.png";
import { H2, H4, H5 } from "../ui/src/components/Typography";
import Drawer from "../components/Drawer";
import { ReactComponent as Heart } from "../assets/heart_lg.svg";

import "../styles/home.scss";

import { APPT_DATE, APPT_TIME } from "../content";
import {
  // modal types
  CONFIRM,
  SCHEDULE,
  DETAILS,
  // home page statuses
  DEFAULT,
  CONFIRMED,
  REGISTERED,
  DISABLED,
} from "../constants";

const Home = () => {
  const location = useLocation();
  const [pageStatus, setPageStatus] = useState(false);
  const [apptTime, setApptTime] = useState(`${APPT_DATE} ${APPT_TIME}`);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [showRecommendationsDrawer, setShowRecommendationsDrawer] =
    useState(true);
  const [showRecommendationsModal, setShowRecommendationsModal] =
    useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params?.get("status") || "default";
    setPageStatus(status.toUpperCase());
  }, [location.search]);

  const { theme } = useTheme();

  const openModal = ({ type }) => {
    setModalType(type);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setAppt = (time) => {
    setApptTime("2022-11-21 " + moment(time, "h:mma").format("HH:mm"));
  };

  const modalTitleMap = {
    SCHEDULE: "Modify Appointment",
    CONFIRM: "Registration: (Dr. Dunham, Sep 10)",
    DETAILS: "Appointment Details",
  };

  const modalContentMap = {
    SCHEDULE: (
      <ScheduleModalContent
        closeModal={closeModal}
        setAppt={setAppt}
        apptTime={apptTime}
      />
    ),
    CONFIRM: <ConfirmModalContent closeModal={closeModal} />,
    DETAILS: (
      <DetailModalContent
        closeModal={closeModal}
        details={location?.state?.details}
      />
    ),
  };

  const buttonOptions = (customStatus = pageStatus) => (
    <div className="button-card">
      {customStatus === DEFAULT && (
        <>
          <H5 bold={true}>Are you still able to attend?</H5>
          <div className="button-row">
            <Link to="/home?status=confirmed">
              <Button color={theme.primaryColor} fullWidth={true}>
                Yes
              </Button>
            </Link>
            <span>
              <Button
                fullWidth={true}
                onClick={() => openModal({ type: SCHEDULE })}
                color={theme.primaryColor}
              >
                No
              </Button>
            </span>
          </div>
        </>
      )}
      {customStatus === CONFIRMED && (
        <>
          <H5 bold={true}>You're confirmed. Please register now</H5>
          <div className="button-row">
            <Link to="/registration?section=patient_details">
              <Button
                color={theme.primaryColor}
                onClick={() => openModal({ type: CONFIRM })}
                fullWidth={true}
              >
                Register this appointment
              </Button>
            </Link>
          </div>
        </>
      )}
      {customStatus === REGISTERED && (
        <Button
          variant="outlined"
          onClick={() => openModal({ type: DETAILS })}
          fullWidth={true}
          color={"#6C6E88"}
        >
          View appointment details
        </Button>
      )}
      {customStatus === DISABLED && (
        <>
          <H5 bold={true}>Are you still able to attend?</H5>
          <div className="button-row">
            <Button fullWidth={true} color={theme.primaryColor}>
              Yes
            </Button>
            <span>
              <Button
                fullWidth={true}
                // onClick={() => openModal({ type: SCHEDULE })}

                color={theme.primaryColor}
              >
                No
              </Button>
            </span>
          </div>
        </>
      )}
    </div>
  );

  const copy = {
    DEFAULT: {
      title: "Hello, Samantha",
      subtitle:
        "The following appointments are ready to be confirmed & registered for check-in:",
    },
    CONFIRMED: {
      title: "Hello, Samantha",
      subtitle:
        "The following appointments are ready to be confirmed & registered for check-in:",
    },
    REGISTERED: {
      title: "Thanks! You're checked-in & ready for your appointment.",
    },
  };

  const badges = (customStatus = pageStatus) => {
    switch (customStatus) {
      case CONFIRMED:
        return [
          { children: "Confirmed", variant: "success" },
          { children: "Registered", variant: "pending" },
        ];
      case REGISTERED:
        return [
          { children: "Confirmed", variant: "success" },
          { children: "Registered", variant: "success" },
        ];
      case DEFAULT:
        return [
          { children: "Confirmed", variant: "pending" },
          { children: "Registered", variant: "pending" },
        ];
      default:
        return [
          { children: "Confirmed", variant: "pending" },
          { children: "Registered", variant: "pending" },
        ];
    }
  };

  return (
    <>
      {pageStatus === REGISTERED && (
        <Toast
          variant="success"
          message="1 appointment registered"
          className="custom-toast"
        />
      )}
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        title={modalTitleMap[modalType]}
      >
        {modalContentMap[modalType]}
      </Modal>
      <div className="home-page">
        <div className="header-section">
          <H2 className="title">{copy[pageStatus]?.title}</H2>
          <H4 bold={true} className="subtitle">
            {copy[pageStatus]?.subtitle}
          </H4>
        </div>
        <Card>
          <div className="image-card">
            <img src={info_icon} alt="icon" />
            <H5>All visits for the week of Nov 21-25</H5>
          </div>
        </Card>
        <AppointmentCard
          arrivalTime={moment(apptTime)
            .subtract(15, "minutes")
            .format("YYYY-MM-DD HH:mm")}
          datetime={apptTime}
          className="appointment-card"
          badgeProps={badges()}
          providerDetailsProps={{
            src: avatar,
            address: "Venture Hills Clinic - Orthopedics",
            occupation: "Orthopedic surgeon",
            name: "Rachel Dunham, DO",
            phoneNumber: "(123) 456-7890",
          }}
          children={buttonOptions()}
        />
        <AppointmentCard
          arrivalTime={moment("2020-11-24 10:15").format("YYYY-MM-DD HH:mm")}
          datetime={moment("2020-11-24 10:30").format("YYYY-MM-DD HH:mm")}
          className="appointment-card"
          badgeProps={badges("DEFAULT")}
          providerDetailsProps={{
            src: avatar,
            address: "Venture Hills Clinic - Orthopedics",
            occupation: "Orthopedic surgeon",
            name: "Rachel Dunham, DO",
            phoneNumber: "(123) 456-7890",
          }}
          children={buttonOptions(DISABLED)}
        />
      </div>
      {pageStatus === REGISTERED && (
        <>
          <Modal
            modalIsOpen={showRecommendationsModal}
            closeModal={() => {
              setShowRecommendationsModal(false);
            }}
            title="Care recommendations"
          >
            <RecommendationsModalContent />
          </Modal>
          {showRecommendationsDrawer && (
            <Drawer
              title="4 new care recommendations"
              setModalIsOpen={() => {
                setShowRecommendationsModal(true);
                setShowRecommendationsDrawer(false);
              }}
              dismissDrawer={() => {
                setShowRecommendationsDrawer(false);
              }}
              isOpen={showRecommendationsDrawer}
            >
              <div className="row">
                <Heart className="heart-lg" fill={theme.primaryColor} />
                <H5>
                  We found recommendations that could improve your care.{" "}
                  <span
                    style={{ color: theme.primaryColor, fontWeight: "600" }}
                  >
                    Click to learn more.
                  </span>
                </H5>
              </div>
            </Drawer>
          )}
        </>
      )}
    </>
  );
};

export default Home;
