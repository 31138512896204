import styled from "styled-components";
import React from "react";
import { Shade80Color } from "../../styles/common";

export interface TypographyProps {
  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * The color of the text.
   * @default Shade80Color
   */
  color?: string;

  /**
   * Whether the font should be bold.
   * @default false
   */
  bold?: boolean;
  children: React.ReactNode;
}

type TypographyElementType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

export const createTypography = (
  elementType: TypographyElementType,
  fontSize: string,
  lineHeight: string
): React.FC<TypographyProps> => {
  const StyledComponent = styled.p.attrs(
    (props: { bold: boolean; color: string }) => ({
      bold: props.bold,
      color: props.color,
    })
  )`
    color: ${(props) => props.color};
    font-weight: ${(props) => (props.bold ? 600 : 400)};
    margin: 0;
    font-size: ${fontSize};
    line-height: ${lineHeight};
  `;

  const TypographyComponent: React.FC<TypographyProps> = ({
    className,
    color = Shade80Color,
    bold = false,
    children,
  }) => {
    return (
      <StyledComponent
        className={className}
        bold={bold}
        color={color}
        as={elementType}
      >
        {children}
      </StyledComponent>
    );
  };

  return TypographyComponent;
};
