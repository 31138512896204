import { RouterProvider } from "react-router-dom";
import React, { useState, useEffect } from "react";
import router from "./router";
import { ToastProvider } from "./ui/src/components/Toast";
import { ThemeProvider } from "./Theme";

function App() {
  return (
    <div className="app">
      <ThemeProvider>
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
