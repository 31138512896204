/**
 * Pill badges are used to indicate a selection of an item, and are typically used when
 * there are more than 1 item selected in a list that is being displayed horizontally.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1812%3A1933
 */

import React from "react";
import styled from "styled-components";

import { ReactComponent as XSvg } from "../../assets/x.svg";
import { H6 } from "../Typography";
import { Shade30Color, Shade50Color, Shade70Color } from "../../styles/common";

interface Props {
  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;

  /**
   * If specified an X icon will be shown.
   */
  onClose?: () => void;
  children: React.ReactNode;
}

const StyledContainer = styled.div`
  background-color: ${Shade30Color};

  border: 0;
  border-radius: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  width: fit-content;
`;

const StyledCloseIcon = styled(XSvg).attrs((props: { $disabled: boolean }) => ({
  $disabled: props.$disabled,
}))`
  color: ${(props) => (props.$disabled ? Shade50Color : Shade70Color)};
  margin-left: 8px;
  height: 0.625rem;
  width: 0.625rem;
  cursor: ${(props) => (props.$disabled ? "default" : "pointer")};
`;

const Pill: React.FC<Props> = ({
  children,
  className,
  disabled = false,
  onClose,
}) => {
  return (
    <StyledContainer className={className}>
      <H6 color={disabled ? Shade50Color : undefined}>{children}</H6>
      {onClose && (
        <StyledCloseIcon
          $disabled={disabled}
          onClick={disabled ? undefined : onClose}
        />
      )}
    </StyledContainer>
  );
};

export default Pill;
