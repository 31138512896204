import React, { useState, useEffect, createContext, useContext } from "react";
import clientData from "./clientData.json";

const ThemeContext = createContext({});

export const useTheme = () => {
  const contextState = useContext(ThemeContext);
  if (contextState === null) {
    throw new Error("useTheme must be used within a theme provider");
  }
  return contextState;
};

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    // if user found in url params && exists in client data
    // set as theme and assign to local storage
    // else if theme in local storage
    // retrieve theme from local storage
    // else
    // fall back to default

    const params = new URLSearchParams(window.location.search);
    try {
      let user = params?.get("user");
      if (clientData[user]) {
        setClient(user);
      } else if (localStorage.getItem("theme")) {
        let client = JSON.parse(localStorage.getItem("theme"));
        setTheme(client);
      } else {
        setClient("default");
      }
    } catch (e) {
      console.log("error accessing localstorage for theme");
      setClient("default");
    }
  }, [false]);

  const setClient = async (user) => {
    const client = {
      slug: user,
      primaryColor: clientData[user]["primaryColor"],
    };
    setTheme(client);
    await localStorage.setItem("theme", JSON.stringify(client));
  };

  return (
    <ThemeContext.Provider value={{ theme }}>
      {theme && props.children}
    </ThemeContext.Provider>
  );
};
