import {
  SELECTION_CARD,
  REVIEW_CARD,
  YES,
  NO,
  REVIEW_INSURANCE,
  REVIEW_BILLING,
  HANDLE_CONFIRM,
  HANDLE_DISMISS,
  OPEN_MODAL,
  DISABLED,
} from "./constants";

// zero-state assets
import patientDetailsImg from "./assets/patient_details_zero_state_img.svg";
import clinicalHistoryImg from "./assets/clinical_history_zero_state_img.svg";
import insuranceBillingImg from "./assets/insurance_billing_zero_state_img.svg";
import documentsImg from "./assets/documents_zero_state_img.svg";

// icon assets
import addressIcon from "./assets/address-icon.svg";
import employerIcon from "./assets/employer-icon.svg";
import medicationIcon from "./assets/medication-icon.svg";
import insuranceIcon from "./assets/insurance-icon.svg";
import billingIcon from "./assets/billing-icon.svg";

export const APPT_DATE = "2022-11-21";
export const APPT_TIME = "13:30";

/*
 * SCHEDULE MODAL CONTENT
 */

export const appointments = [
  {
    date: "Nov 18",
    day: "Tomorrow",
    times: ["1:30pm", "2:30pm"],
  },
  {
    date: "Nov 21",
    day: "Monday",
    times: ["8:00am", "1:15pm"],
  },
  {
    date: "Nov 22",
    day: "Tuesday",
    times: [
      "9:15pm",
      "10:00am",
      "11:15pm",
      "12:00pm",
      "1:15pm",
      "2:00pm",
      "2:15pm",
      "3:00pm",
      "4:15pm",
      "5:00pm",
    ],
  },
  {
    date: "Nov 23",
    day: "Wednesday",
    times: ["9:00am", "12:15pm", "1:45pm", "2:30pm"],
  },
  {
    date: "Nov 24",
    day: "Thursday",
    times: ["1:00pm", "3:30pm", "4:30pm"],
  },
  {
    date: "Nov 25",
    day: "Friday",
    times: ["10:30am", "12:30pm"],
  },
  {
    date: "Nov 28",
    day: "Monday",
    times: ["8:30am", "10:15am", "11:00am", "1:30pm", "4:00pm"],
  },
];

export const registrationDetails = {
  PATIENT_DETAILS: [
    {
      label: "Reviewing personal information...",
      header: "Patient details",
      fields: [
        { label: "First name", value: "Samantha" },
        {
          label: "Last name",
          value: "Smith",
        },
        {
          label: "Date of birth",
          value: "10 / 12/ 1969",
        },
        {
          label: "Language",
          value: "English",
        },
        {
          label: "Sex",
          value: "Female",
        },
      ],
    },
    {
      label: "Reviewing demographics...",
      header: "Demographics",
      fields: [
        { label: "Race", value: "White" },
        { label: "Ethnicity", value: "Not Hispanic or Latino" },
        { label: "Language", value: "English" },
      ],
    },
    {
      label: "Reviewing contact information...",
      header: "Contact Information",
      fields: [
        { label: "Mobile phone", value: "(123) 456-7890" },
        { label: "Email address", value: "samk@gmail.com" },
        {
          label: "Mailing address",
          value:
            "<span class='error'>4200 Sailboat Ave, San Mateo, CA 94404</span>",
          shouldReview: true,

          isReviewed: false,
          toastMessage: "Address updated",
          reviewItem: {
            type: SELECTION_CARD,
            title: "Address mismatch",
            icon: addressIcon,
            bodyText:
              "Your address on file does not match the USPS format. Would you like to update it?",
            options: [
              {
                key: NO,
                label: "No",
                value: "<span>4200 Sailboat Ave, San Mateo, CA 94404</span>",
                clickHandler: HANDLE_CONFIRM,
              },
              {
                key: YES,
                label: "Yes",
                value:
                  "<span class='success'>4200 SAILBOAT AVE, SAN MATEO, CA 94404</span>",
                clickHandler: HANDLE_CONFIRM,
              },
            ],
          },
        },
      ],
    },
    {
      label: "Reviewing marital status...",
      header: "Marital status",
      fields: [
        {
          label: "Status",
          value: "Married",
        },
        {
          label: "Spouse's name",
          value: "Jacob",
        },
        {
          label: "Spouse's phone",
          value: "(345) 678-9898",
        },
        {
          label: "Emergency contact",
          value: "Yes",
        },
      ],
    },
    {
      label: "Reviewing employer status...",
      header: "Employer status",
      fields: [
        { label: "Status", value: "Employed" },
        {
          label: "Employer",
          value: "<span class='error'>Sweetgreen</span>",
          shouldReview: true,
          isReviewed: false,
          toastMessage: "Employer updated",
          reviewItem: {
            type: SELECTION_CARD,
            title: "Employer change",
            icon: employerIcon,
            bodyText:
              "We noticed a recent change in employment. Is your new employer Starbucks Corp.?",
            options: [
              {
                key: NO,
                label: "No",
                value: "Sweetgreen",
                clickHandler: HANDLE_CONFIRM,
              },
              {
                key: YES,
                label: "Yes",
                value: "<span class='success'>Starbucks</span>",
                clickHandler: HANDLE_CONFIRM,
              },
            ],
          },
        },
      ],
    },
  ],
  CLINICAL_HISTORY: [
    {
      label: "Reviewing medications...",
      header: "Medications",
      fields: [
        {
          label: "Medication",
          value:
            "Lexapro<br />50mg, once daily<br /><span class='error'>Taking as prescribed</span>",
          shouldReview: true,
          isReviewed: false,
          toastMessage: "Medication updated",
          reviewItem: {
            type: SELECTION_CARD,
            title: "Medication update",
            icon: medicationIcon,
            bodyText:
              "We noticed a lapse in your Lexapro prescription refills. Are you still taking this?",
            options: [
              {
                key: NO,
                label: "No",
                value:
                  "Lexapro<br />50mg, once daily<br /><span>No longer taking</span>",
                clickHandler: HANDLE_CONFIRM,
              },
              {
                key: YES,
                label: "Yes",
                value:
                  "Lexapro<br />50mg, once daily<br /><span class='success'>Taking as prescribed</span>",
                clickHandler: HANDLE_CONFIRM,
              },
            ],
          },
        },
      ],
    },
    {
      label: "Reviewing allergies...",
      header: "Allergies",
      fields: [
        {
          label: "Allergy",
          value: "No allergies",
        },
      ],
    },
    {
      label: "Reviewing medical history...",
      header: "Medical history",
      fields: [
        {
          label: "Condition",
          value: "Depression",
        },
        {
          label: "Condition",
          value: "Type II Diabetes",
          hasRecommendation: true,
          recommendationComplete: false,
          recommendationItem: true,
        },
      ],
    },
    {
      label: "Reviewing family history...",
      header: "Family history",
      fields: [
        {
          label: "Condition",
          value: "Depression<br />Father",
        },
        {
          label: "Condition",
          value: "High blood pressure<br />Mother",
        },
      ],
    },
    {
      label: "Reviewing pharmacy information...",
      header: "Pharmacy information",
      fields: [
        {
          label: "Pharmacy",
          value: "Walgreens<br />4141 S San Vicenty Blvd, San Mateo, CA  94404",
        },
      ],
    },
  ],
  INSURANCE_BILLING: [
    {
      label: "Reviewing primary insurance...",
      header: "Primary insurance",
      fields: [
        { label: "", value: "On file" },
        { label: "Plan", value: "Blue Shield of CA" },
        { label: "Member ID", value: "2424" },
        { label: "Group number", value: "BS1234567890" },
        { label: "Subscriber", value: "Samantha Smith" },
        { label: "400", value: "–" },
        { label: "Image", value: "On file" },
      ],
    },
    {
      label: "Reviewing additional insurances...",
      header: "Additional insurances",
      fields: [{ label: "Status", value: "On file" }],
    },
    {
      label: "Reviewing insurance eligibility...",
      header: "Eligibility",
      fields: [
        {
          label: "Status",
          value: "Review due to employer change",
          shouldReview: true,
          isReviewed: false,
          toastMessage: "Insurance updated",
          reviewItem: {
            key: REVIEW_INSURANCE,
            type: REVIEW_CARD,
            title: "Potential insurance update",
            icon: insuranceIcon,
            bodyText:
              "Did you have any changes to your insurance  due to your recent change in employment?",
            options: [
              {
                key: NO,
                label: "No",
                value: "Review due to employer change",
                clickHandler: DISABLED,
              },
              {
                key: YES,
                label: "Yes",
                value: "<span class='success'>Eligible</span>",
                clickHandler: OPEN_MODAL,
              },
            ],
          },
        },
      ],
    },
    {
      label: "Reviewing billing estimate...",
      header: "Billing estimate",
      fields: [
        { label: "Visit fees", value: "$500.00" },
        { label: "In-network deductible", value: "$0.00 remaining" },
        {
          label: "Co-insurance",
          value: "($450)<br />Applied - 90% of visit fee",
        },
        { label: "Prepayment", value: "$100.00" },
        { label: "Co-payment", value: "$30.00" },
        { label: "Total out-of-pocket", value: "$130.00" },
      ],
    },
    {
      label: "Reviewing payments due...",
      header: "Payments",
      fields: [
        { label: "Card on file", value: "•••• •••• •••• 4444" },
        { label: "Prior balance due", value: "$565.00" },
        { label: "Co-payment due", value: "$30.00" },
        { label: "Pre-payment due", value: "$100.00" },
        {
          label: "Total due",
          value: "$695.00",
          shouldReview: true,
          isReviewed: false,
          toastMessage: "Billing updated",
          reviewItem: {
            key: REVIEW_BILLING,
            type: REVIEW_CARD,
            title: "Bill due for visit",
            icon: billingIcon,
            bodyText:
              "You have a balance due. Please click to review payment options.",
            options: [
              {
                key: YES,
                label: "Review bill",
                value: "<span class='success'>$695.00</span>",
                clickHandler: OPEN_MODAL,
              },
            ],
          },
        },
        { label: "Payment plan", value: "-" },
      ],
    },
  ],
  DOCUMENTS: [
    {
      label: "Reviewing documents...",
      header: "Documents",
      fields: [
        { label: "Consent form", value: "Reviewed 4/12/22" },
        { label: "Release of PHI", value: "Reviewed 2/04/22" },
      ],
    },
    {
      label: "Reviewing visit instructions...",
      header: "Visit instructions",
      fields: [{ label: "View instructions", value: "–" }],
    },
    {
      label: "Reviewing eSignatures...",
      header: "eSignatures",
      fields: [{ label: "View signatures", value: "Last signed 4/12/22" }],
    },
  ],
};

export const sectionContentMap = {
  PATIENT_DETAILS: {
    title: "Review your personal information",
    subtitle:
      "Reviewing the following keeps all your personal information up-to-date & secure.",
    link: "/registration?section=clinical_history",
    buttonText: "Continue to clinical history",
    zeroStateImage: patientDetailsImg,
  },
  CLINICAL_HISTORY: {
    title: "Review your clinical history",
    subtitle:
      "Reviewing the following ensures your provider has a better picture of your current health.",
    link: "/registration?section=insurance_billing",
    buttonText: "Continue to insurance & billing",
    zeroStateImage: clinicalHistoryImg,
  },
  INSURANCE_BILLING: {
    title: "Review your insurance & billing",
    subtitle:
      "Reviewing your insurance information helps make sure you have more accurate claims & billing.",
    link: "/registration?section=documents",
    buttonText: "Continue to documents",
    zeroStateImage: insuranceBillingImg,
  },
  DOCUMENTS: {
    title: "Review your visit documents",
    subtitle:
      "Reviewing the below ensures you've been informed of important clinical disclosures.",
    link: "/home?status=registered",
    buttonText: "Complete registration",
    zeroStateImage: documentsImg,
  },
};
