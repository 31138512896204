// modal types
export const CONFIRM = "CONFIRM";
export const SCHEDULE = "SCHEDULE";
export const DETAILS = "DETAILS";

// home page states
export const DEFAULT = "DEFAULT";
export const CONFIRMED = "CONFIRMED";
export const REGISTERED = "REGISTERED";

// review page steps
export const INSURANCE = "INSURANCE";
export const BILLING = "BILLING";

// schedule confirmation popup types
export const RESCHEDULE = "RESCHEDULE";
export const CANCEL = "CANCEL";

// registration section pages
export const PATIENT_DETAILS = "PATIENT_DETAILS";
export const CLINICAL_HISTORY = "CLINICAL_HISTORY";
export const INSURANCE_BILLING = "INSURANCE_BILLING";
export const DOCUMENTS = "DOCUMENTS";

// review card types
export const SELECTION_CARD = "SELECTION_CARD";
export const REVIEW_CARD = "REVIEW_CARD";

// keys for to map registration data from content.js to application components
export const YES = "YES";
export const NO = "NO";
export const REVIEW_BILLING = "REVIEW_BILLING";
export const REVIEW_INSURANCE = "REVIEW_INSURANCE";

// review item card event handler keys
export const HANDLE_CONFIRM = "HANDLE_CONFIRM";
export const HANDLE_DISMISS = "HANDLE_DISMISS";
export const OPEN_MODAL = "OPEN_MODAL";
export const DISABLED = "DISABLED";

// insurance modal states
export const VIEW_CARD = "VIEW_CARD";
export const VIEW_CARDS = "VIEW_CARDS";
export const CAMERA = "CAMERA";
export const PROCESSING = "PROCESSING";

// billing modal states
export const SELECT_BALANCE = "SELECT_BALANCE";
export const SELECT_PAYMENT = "SELECT_PAYMENT";
export const PAYMENT_PLAN = "PAYMENT_PLAN";
export const CONFIRM_TOTAL = "CONFIRM_TOTAL";

// payment options
export const CREDIT_CARD_OPT = "CREDIT_CARD_OPT";
export const PAYMENT_PLAN_OPT = "PAYMENT_PLAN_OPT";
export const PAYPAL_OPT = "PAYPAL_OPT";
export const APPLE_PAY_OPT = "APPLE_PAY_OPT";
