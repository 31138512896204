import React from "react";
import { Link } from "react-router-dom";
import Card from "../Card";

import Button from "../../ui/src/components/Button/Button.tsx";

import "../../styles/confirmappt.scss";

const ConfirmModalContent = (props) => {
  return (
    <div className="confirm-modal-content">
      <div>
        Reviewing your patient information on file to ensure everything is up to
        date. This may take a few moments to complete.
      </div>
      <div className="card-section">
        <Card>Personal Information</Card>
        <Card>Clinical History</Card>
        <Card>Insurance & Payments</Card>
        <Card>Documents & Signatures</Card>
      </div>
      <Link to="/review?process=insurance">
        <Button type="submit" fullWidth={true}>
          Sign in
        </Button>
      </Link>
    </div>
  );
};

export default ConfirmModalContent;
