import React, { useState } from "react";
import Button from "../../ui/src/components/Button/Button.tsx";
import "../../styles/schedule.scss";
import AppointmentCard from "../../components/AppointmentCard";
import moment from "moment";
import avatar from "../../assets/avatar.png";
import { H4, H5 } from "../../ui/src/components/Typography";
import Confirm from "../../ui/src/components/Confirm";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";

import { useTheme } from "../../Theme";
// data
import { appointments } from "../../content";
import { RESCHEDULE, CANCEL } from "../../constants";

const ScheduleModalContent = (props) => {
  const { setAppt, apptTime } = props;
  const [tmpApptTime, setTmpApptTime] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState(RESCHEDULE);

  const { theme } = useTheme();

  const updateAppt = (val) => {
    setAppt(val);
    props.closeModal();
  };

  const buttonOptions = (
    <H5
      bold={true}
      className="cancel-link"
      onClick={() => {
        showConfirmPopup(null, CANCEL);
      }}
      color={theme.primaryColor}
    >
      Cancel appointment
    </H5>
  );

  const confirmHandler = (type) => {
    if (type === RESCHEDULE) {
      updateAppt(tmpApptTime);
    }
    setShowPopup(false);
  };

  const dismissHandler = () => {
    setTmpApptTime("");

    setShowPopup(false);
  };

  const showConfirmPopup = (appt, type) => {
    setPopupType(type);
    if (type === RESCHEDULE) {
      setTmpApptTime(appt);
    }
    setShowPopup(true);
  };

  const confirmContent = {
    RESCHEDULE: {
      title: "Would you like to reschedule?",
      bodyText:
        "You will lose this appointment time and this action cannot be undone.",
      confirmText: "Yes, reschedule",
      dismissText: "Nevermind",
    },
    CANCEL: {
      title: "Are you sure?",
      bodyText:
        "Cancelling this appointment can not be undone and you will lose your appointment time.",
      confirmText: "Yes, cancel",
      dismissText: "Nevermind",
    },
  };

  return (
    <div className="schedule-appt-modal">
      <Confirm
        icon={<WarningIcon />}
        isOpen={showPopup}
        title={confirmContent[popupType]?.title}
        bodyText={confirmContent[popupType]?.bodyText}
        confirmHandler={() => confirmHandler(popupType)}
        dismissHandler={() => dismissHandler()}
        confirmText={confirmContent[popupType]?.confirmText}
        dismissText={confirmContent[popupType]?.dismissText}
        themeColor={theme.primaryColor}
      />
      <AppointmentCard
        arrivalTime={moment(apptTime)
          .subtract(15, "minutes")
          .format("YYYY-MM-DD HH:mm")}
        datetime={apptTime}
        badgeProps={{ children: "Confirmed", variant: "pending" }}
        providerDetailsProps={{
          src: avatar,
          address: "Venture Hills Clinic - Orthopedics",
          occupation: "Orthopedic surgeon",
          name: "Rachel Dunham, DO",
          phoneNumber: "(123) 456-7890",
        }}
        children={buttonOptions}
      />
      <H4 className="text-section">
        Select a new time for your visit below, or cancel if you can no longer
        attend.
      </H4>
      <div>
        {appointments.map((row, i) => {
          return (
            <div className="appt-row">
              <div className="header">
                <H4 bold={true} className="date">
                  {row.date},
                </H4>
                <H4>&nbsp;{row.day}</H4>
              </div>
              <div className="appt-grid">
                {row.times.map((appt, i) => {
                  return (
                    <Button
                      color={theme.primaryColor}
                      onClick={() => showConfirmPopup(appt, RESCHEDULE)}
                    >
                      <H5 bold={true} color="#fff">
                        {appt}
                      </H5>
                    </Button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleModalContent;
