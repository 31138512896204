import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";

import "../styles/layout.scss";

const Layout = (props) => {
  const [pageClass, setPageClass] = useState("page");
  const location = useLocation();
  useEffect(() => {
    const alternateLayout = document.location.href.match(/signup/);
    setPageClass(alternateLayout ? "alternate-page" : "page");
  }, [location]);

  return (
    <div className="layout">
      <Navbar />
      <div className={pageClass}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
