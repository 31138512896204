/**
 * Badges are meant to be used when you need to indicate a state of an item, typically within a row.
 * You can use a badge next to a title, or as an element within a list row.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1812%3A1933
 */

import React, { ForwardedRef } from 'react';
import './badge.scss';

export type BadgeVariant = 'success' | 'warning' | 'danger' | 'pending';

export interface BadgeProps {
  /**
   * The content of the component.
   */
  children: React.ReactNode;

  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * The variant to use.
   * @default 'success'
   */
  variant?: BadgeVariant;
}

const Badge = React.forwardRef(
  (
    { children, className, variant = 'success' }: BadgeProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const classes = [`badge-${variant}`, className].filter(Boolean).join(' ');
    return (
      <div ref={ref} className={classes}>
        {children}
      </div>
    );
  }
);

export default Badge;
