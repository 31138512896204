import React, { useState } from "react";
import arrowDown from "../../assets/arrow_down.svg";
import arrowRight from "../../assets/arrow_right.svg";
import Card from "../../components/Card";
import { P2, P3, H3, H4, H5 } from "../../ui/src/components/Typography";
import { Button } from "../../ui/src/components";
import Confirm from "../../ui/src/components/Confirm";
import { useTheme } from "../../Theme";
import Checkbox from "../Checkbox";
import Radio from "../Radio";

import { monetize } from "../../utils";

import {
  SELECT_BALANCE,
  SELECT_PAYMENT,
  PAYMENT_PLAN,
  CONFIRM_TOTAL,
  CREDIT_CARD_OPT,
  PAYPAL_OPT,
  APPLE_PAY_OPT,
  PAYMENT_PLAN_OPT,
} from "../../constants";

import infoIcon from "../../assets/info_icon.svg";
import creditCard from "../../assets/credit_card.svg";
import applePay from "../../assets/apple_pay.svg";
import paypal from "../../assets/paypal.svg";
import expandedPaypal from "../../assets/expanded_paypal.svg";
import expandedPaymentPlan from "../../assets/expanded_payment_plan.svg";

import { ReactComponent as Search } from "../../assets/search-payments.svg";
import "../../styles/billing.scss";

const ReviewBillingModalContent = (props) => {
  const { handleUpdate, modalArgs, closeModal } = props;
  const [step, setStep] = useState(SELECT_BALANCE);
  const [totalBalance, setTotalBalance] = useState(695);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const stepComponentMap = {
    SELECT_BALANCE: (
      <SelectBalance
        setStep={setStep}
        totalBalance={totalBalance}
        setTotalBalance={setTotalBalance}
      />
    ),
    SELECT_PAYMENT: (
      <SelectPayment
        setStep={setStep}
        totalBalance={totalBalance}
        setTotalBalance={setTotalBalance}
        closeModal={closeModal}
        handleUpdate={handleUpdate}
        modalArgs={modalArgs}
      />
    ),
    PAYMENT_PLAN: (
      <PaymentPlan
        setStep={setStep}
        totalBalance={totalBalance}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
    ),
    CONFIRM_TOTAL: (
      <ConfirmTotal
        closeModal={closeModal}
        handleUpdate={handleUpdate}
        modalArgs={modalArgs}
        totalBalance={totalBalance}
        setStep={setStep}
        selectedPlan={selectedPlan}
      />
    ),
  };

  return <div className="billing-modal">{stepComponentMap[step]}</div>;
};

const ConfirmTotal = (props) => {
  const {
    closeModal,
    modalArgs,
    handleUpdate,
    totalBalance,
    selectedPlan = 0,
  } = props;
  const { theme } = useTheme();
  const [selected, setSelected] = useState(0);
  const [tos, setTos] = useState(false);

  const paymentPlans = [
    {
      duration: 3,
      apr: 0,
      interest: 0,
    },
    {
      duration: 6,
      apr: 14.51,
      interest: (totalBalance * 0.1451) / 2,
    },
    {
      duration: 12,
      apr: 25.84,
      interest: totalBalance * 0.2584,
    },
  ];

  const plan = paymentPlans[selectedPlan];
  const monthlyPayment = monetize(
    (totalBalance + plan?.interest) / plan?.duration
  );

  return (
    <div className="confirm-total-section">
      <H4 bold={true} className="title">
        Review the following details of your payment plan to confirm your
        application:
      </H4>
      <div className="card total-card info-card">
        <div className="header-row">
          <H3 bold={true}>
            {monetize((totalBalance + plan?.interest) / plan?.duration)}
          </H3>
          <P3>&nbsp;/ month over {plan?.duration} months</P3>
        </div>
        <div className="rate-row">
          <div>
            <P3 bold={true}>APR</P3>
            <P2>{plan.apr}%</P2>
          </div>
          <div>
            <P3 bold={true}>Interest</P3>
            <P2>{monetize(plan.interest)}</P2>
          </div>
          <div>
            <P3 bold={true}>Loan total</P3>
            <P2>{monetize(totalBalance + plan?.interest)}</P2>
          </div>
        </div>
        <H4 bold={true}>Your payment schedule:</H4>
        <div className="payment-item-row">
          <span className="circle closed"></span>
          <P3>Dec 22, 2022</P3>
          <P3 className="price">{monthlyPayment}</P3>
        </div>
        <div className="payment-item-row">
          <span className="circle"></span>
          <P3>Jan 22, 2023</P3>
          <P3 className="price">{monthlyPayment}</P3>
        </div>
        <div className="payment-item-row">
          <span className="circle"></span>
          <P3>Feb 22, 2023</P3>
          <P3 className="price">{monthlyPayment}</P3>
        </div>
      </div>
      <H4 bold={true} className="title">
        Select a payment method for this loan
      </H4>
      <div className="select-card">
        <div className="select-row">
          <Radio checked={selected === 0} onChange={() => setSelected(0)} />

          <label for="credit">
            <img src={creditCard} alt="credit card symbol" />
            <span>•••• 4824</span>
            <span className="date">09/20</span>
          </label>
        </div>
        <div className="select-row">
          <Radio checked={selected === 1} onChange={() => setSelected(1)} />
          <label for="credit">
            <H4 bold={true}>Add a new card</H4>
          </label>
        </div>
        <div className="select-row">
          <Radio checked={selected === 2} onChange={() => setSelected(2)} />
          <label for="credit">
            <H4 bold={true}>Bank account</H4>
          </label>
        </div>
      </div>
      <div className="card info-card">
        <img src={infoIcon} alt="info icon" />
        <P3>
          By continuing, you authorize the following payments to be deducted
          automatically using the payment method listed above
        </P3>
      </div>
      <div className="disclaimer">
        <Checkbox
          label={
            <P2>
              I have reviewed the{" "}
              <span className="underline">Loan Agreement</span> and{" "}
              <span className="underline">Credit Score Disclosure.</span>
            </P2>
          }
          checked={tos}
          onClick={() => setTos(!tos)}
        />
      </div>
      <div className="button-group">
        <Button
          color={theme.primaryColor}
          fullWidth="true"
          onClick={() => {
            closeModal();
            handleUpdate(...Object.values(modalArgs));
          }}
          disabled={!(tos && selected === 0)}
        >
          Confirm & submit
        </Button>
      </div>
    </div>
  );
};

const PaymentPlan = (props) => {
  const { setStep, totalBalance, selectedPlan, setSelectedPlan } = props;
  const [expandedPaymentPlans, setExpandedPaymentPlans] = useState({3: false, 6: false, 12: false})

  const { theme } = useTheme();

  const sixMonthInterest = (totalBalance * 0.1451) / 2;
  const twelveMonthInterest = totalBalance * 0.2584;

  return (
    <div className="payment-plan-section">
      <H4 bold={true}>
        Based on your financial profile, you're pre-approved for the below
        options:
      </H4>
      <div className="card">
        <H4 bold={true}>Total due:</H4>
        <H4 bold={true}>{monetize(totalBalance)}</H4>
      </div>
      <div className="selection-card">
        <div className="selection">
          <div className="input-col">
            <Radio
              checked={selectedPlan === 0}
              onChange={() => setSelectedPlan(0)}
            />
          </div>

          <div className="content">
            <H5
              bold={true}
              color={theme.primaryColor}
              className="recommendation-label"
            >
              Recommended
            </H5>
            <div className="payment-row">
              <div>
                <span className="payment">{monetize(totalBalance / 3)}</span>
                <span className="duration">&nbsp;/ month</span>
              </div>
              <div className="see-more" onClick={() => setExpandedPaymentPlans(prev => ({...prev, '3': !prev['3']}))}>
                <P3 color={theme.primaryColor} className="duration-description">
                  over 3 months
                </P3>
                {expandedPaymentPlans['3'] ? <img src={arrowDown} alt={"arrow down"} /> : <img src={arrowRight} alt="arrow right" />}
              </div>
            </div>
            {expandedPaymentPlans["3"] && (
              <div className="installment-widget">
                <ul className="bar">
                  {['Dec 22, 2022', 'Jan 22, 2023', 'Feb 22, 2023'].map((date, i, arr) => (
                    <li key={date}>
                      {date}<span>{monetize(totalBalance / arr.length)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="rate-row">
              <div>
                <P3 className="label" bold={true}>
                  APR
                </P3>
                <P3 className="value">0.00%</P3>
              </div>
              <div>
                <P3 className="label" bold={true}>
                  Interest
                </P3>
                <P3 className="value">$0.00</P3>
              </div>
              <div>
                <P3 className="label" bold={true}>
                  Loan total
                </P3>
                <P3 className="value">${totalBalance}.00</P3>
              </div>
            </div>
          </div>
        </div>
        <div className="selection">
          <div className="input-col">
            <Radio
              checked={selectedPlan === 1}
              onChange={() => setSelectedPlan(1)}
            />
          </div>
          <div className="content">
            <div className="payment-row">
              <div>
                <span className="payment">
                  {monetize((totalBalance + sixMonthInterest) / 6)}
                </span>
                <span className="duration">&nbsp;/ month</span>
              </div>
              <div className="see-more" onClick={() => setExpandedPaymentPlans(prev => ({...prev, '6': !prev['6']}))}>
                <P3 color={theme.primaryColor} className="duration-description">
                  over 6 months
                </P3>
                {expandedPaymentPlans['6'] ? <img src={arrowDown} alt={"arrow down"} /> : <img src={arrowRight} alt="arrow right" />}
              </div>
            </div>
            {expandedPaymentPlans['6'] && (
              <div className="installment-widget">
                <ul className="bar">
                  {['Dec 22, 2022', 'Jan 22, 2023', 'Feb 22, 2023',
                    'Mar 22, 2023', 'Apr 22, 2023', 'May 22, 2023'].map((date, i, arr) => (
                    <li key={date}>
                      {date}<span>{monetize(totalBalance / arr.length)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="rate-row">
              <div>
                <P3 className="label">APR</P3>
                <P3>14.51%</P3>
              </div>
              <div>
                <P3 className="label">Interest</P3>
                <P3>{monetize(sixMonthInterest)}</P3>
              </div>
              <div>
                <P3 className="label">Loan total</P3>
                <P3>{monetize(totalBalance + sixMonthInterest)}</P3>
              </div>
            </div>
          </div>
        </div>
        <div className="selection">
          <div className="input-col">
            <Radio
              checked={selectedPlan === 2}
              onChange={() => setSelectedPlan(2)}
            />
          </div>

          <div className="content">
            <div className="payment-row">
              <div>
                <span className="payment">
                  {monetize((totalBalance + sixMonthInterest) / 12)}
                </span>
                <span className="duration">&nbsp;/ month</span>
              </div>
              <div className="see-more" onClick={() => setExpandedPaymentPlans(prev => ({...prev, '12': !prev['12']}))}>
                <P3 color={theme.primaryColor} className="duration-description">
                  over 12 months
                </P3>
                {expandedPaymentPlans['12'] ? <img src={arrowDown} alt={"arrow down"} /> : <img src={arrowRight} alt="arrow right" />}
              </div>
            </div>
            {expandedPaymentPlans['12'] && (
              <div className="installment-widget">
                <ul className="bar">
                  {['Dec 22, 2022', 'Jan 22, 2023', 'Feb 22, 2023',
                    'Mar 22, 2023', 'Apr 22, 2023', 'May 22, 2023',
                    'Jun 22, 2023', 'Jul 22, 2023', 'Aug 22, 2023',
                    'Sep 22, 2023', 'Oct 22, 2023', 'Nov 22, 2023'].map((date, i, arr) => (
                    <li key={date}>
                      {date}<span>{monetize(totalBalance / arr.length)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="rate-row">
              <div>
                <P3 className="label">APR</P3>
                <P3>25.84%</P3>
              </div>
              <div>
                <P3 className="label">Interest</P3>
                <P3>{monetize(twelveMonthInterest)}</P3>
              </div>
              <div>
                <P3 className="label">Loan total</P3>
                <P3>{monetize(totalBalance + twelveMonthInterest)}</P3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-group">
        <Button
          color={theme.primaryColor}
          onClick={() => setStep(CONFIRM_TOTAL)}
          fullWidth={true}
        >
          Select option
        </Button>
      </div>
    </div>
  );
};

const SelectPayment = (props) => {
  const { setStep, totalBalance, handleUpdate, modalArgs, closeModal } = props;
  const [selectedOption, setSelectedOption] = useState(CREDIT_CARD_OPT);
  const { theme } = useTheme();

  return (
    <div className="select-payment-section">
      <H4 bold={true}>Select your payment method</H4>
      <div className="select-card">
        <div className="select-row">
          <Radio
            checked={selectedOption === CREDIT_CARD_OPT}
            onChange={() => setSelectedOption(CREDIT_CARD_OPT)}
          />
          <label for="credit">
            <img src={creditCard} alt="credit card logo" />
            <span>•••• 4824</span>
            <span className="date">09/20</span>
          </label>
        </div>
        <div className="select-row">
          <Radio
            checked={selectedOption === APPLE_PAY_OPT}
            onChange={() => setSelectedOption(APPLE_PAY_OPT)}
          />
          <label for="credit">
            <img src={applePay} alt="apple pay logo" />
          </label>
        </div>
        <div className="select-row">
          <Radio
            checked={selectedOption === PAYPAL_OPT}
            onChange={() => setSelectedOption(PAYPAL_OPT)}
          />
          <label for="credit">
            <img src={paypal} alt="paypal logo" />
          </label>
        </div>
        {selectedOption === PAYPAL_OPT && (
          <div className="expanded">
            <img src={expandedPaypal} alt="paypal icon" />
            <P3>
              You will be redirected to PayPal to complete your purchase
              securely.
            </P3>
          </div>
        )}
        <div className="select-row payment-plan">
          <Radio
            checked={selectedOption === PAYMENT_PLAN_OPT}
            onChange={() => setSelectedOption(PAYMENT_PLAN_OPT)}
          />
          <label for="credit">
            <H4 bold={true}>Payment plan</H4>
          </label>
        </div>
        {selectedOption === PAYMENT_PLAN_OPT && (
          <div className="expanded">
            <img src={expandedPaymentPlan} alt="expanded imaged" />
            <P3>
              We offer payment plans to anyone who needs assistance with their
              bills. Click the button below to review your options.
            </P3>
          </div>
        )}
      </div>
      <div className="card billing-card">
        <H4 bold={true} className="price row">
          <span>Total due:</span>
          <span>${totalBalance}.00</span>
        </H4>
        {selectedOption === CREDIT_CARD_OPT && (
          <Button
            color={theme.primaryColor}
            fullWidth={true}
            onClick={() => {
              closeModal();
              handleUpdate(...Object.values(modalArgs));
            }}
          >
            Pay now
          </Button>
        )}
        {selectedOption === PAYMENT_PLAN_OPT && (
          <Button
            color={theme.primaryColor}
            fullWidth={true}
            onClick={() => {
              setStep(PAYMENT_PLAN);
            }}
          >
            Continue to payment plans
          </Button>
        )}
        {selectedOption === PAYPAL_OPT && (
          <Button disabled fullWidth={true}>
            Continue to PayPal
          </Button>
        )}
        {selectedOption === APPLE_PAY_OPT && (
          <Button disabled fullWidth={true}>
            Continue to Apple Pay
          </Button>
        )}
      </div>
    </div>
  );
};

const SelectBalance = (props) => {
  const { setStep, totalBalance, setTotalBalance } = props;
  const [selected, setSelected] = useState([true, true, true]);
  const [showPopup, setIsOpen] = useState(0);
  const { theme } = useTheme();

  const updateSelected = (selected, index, amount) => {
    // 0 = closed; 1 = open, -1 = closed forever
    if (showPopup > -1) {
      setIsOpen(true);
    }

    if (selected) {
      setTotalBalance(totalBalance - amount);
      setSelected((prevState) => {
        prevState[index] = false;
        return {
          ...prevState,
        };
      });
    } else {
      setTotalBalance(totalBalance + amount);
      setSelected((prevState) => {
        prevState[index] = true;
        return {
          ...prevState,
        };
      });
    }
  };

  const confirmAndNavigate = () => {
    setIsOpen(-1);
    setStep(PAYMENT_PLAN);
  };

  return (
    <div div className="select-balance-section">
      <Confirm
        icon={<Search />}
        isOpen={showPopup > 0}
        title="Would you like to see options to pay your full balance?"
        bodyText="To help make payments easier, we offer plans to pay over time. Would you like to learn more?"
        confirmHandler={confirmAndNavigate}
        confirmText="Yes"
        dismissHandler={() => setIsOpen(-1)}
        dismissText="No thanks"
        themeColor={theme.primaryColor}
      />
      <H4 bold="true">
        You have a copay, prepayment, and prior balance due. Select the items
        you’d like to pay now.
      </H4>
      <Card className="card select-card">
        <div>
          <div className="row">
            <Checkbox
              label={<P2>Co-pay</P2>}
              checked={selected[0]}
              onClick={(val) => updateSelected(selected[0], 0, 30)}
            />
            <P2 clasName="price">$30.00</P2>
          </div>
          <div className="row">
            <Checkbox
              label={<P2>Prepayment</P2>}
              checked={selected[1]}
              onClick={(val) => updateSelected(selected[1], 1, 100)}
            />
            <P2 className="price">$100.00</P2>
          </div>
          <div className="row">
            <Checkbox
              label={<P2>Prior balance</P2>}
              checked={selected[2]}
              onClick={(val) => updateSelected(selected[2], 2, 565)}
            />
            <P2 className="price">$565.00</P2>
          </div>
          <div className="row">
            <H4 bold={true}>Total due:</H4>
            <H4 bold={true} className="price">
              ${totalBalance}.00
            </H4>
          </div>
        </div>
      </Card>
      <Card className="card info-card">
        <img src={infoIcon} alt="info icon" />
        <P3>
          Full payment is not required at this time but will save you time when
          you arrive.
        </P3>
      </Card>
      <div className="button-group">
        <Button
          fullWidth={true}
          onClick={() => setStep(SELECT_PAYMENT)}
          color={theme.primaryColor}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ReviewBillingModalContent;
