/**
 * ProviderDetails show off details about a given provider to be used as
 * a standalone component or within cards or other containers.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=2110%3A5101
 */

import React, { ForwardedRef } from "react";
import styled from "styled-components";
import Avatar from "../Avatar/Avatar";
import H5 from "../Typography/H5";
import P3 from "../Typography/H3";
import { Shade00Color, Shade70Color } from "../../styles/common";
import {
  formatForAddress,
  formatForEmail,
  formatForTelephone,
} from "../../utils/link";

interface Props {
  /**
   * The address of the provider.
   * @example 123 Example St, San Mateo, CA 94402
   */
  address?: string;

  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * The email of the provider.
   * @example provider@example.com
   */
  email?: string;

  /**
   * The name of the provider.
   * This name will also be used as the alt for the provider image.
   */
  name: string;

  /**
   * The occupation of the provider.
   * @example Orthopedic Surgeon
   */
  occupation: string;

  /**
   * The provider's department.
   * @example Orthopedics
   */
  department?: string;

  /**
   * The provider's phone number.
   * @example (415) 123-4567
   */
  phoneNumber?: string;

  /**
   * The src attribute for the provider image.
   */
  src?: string;
}

const Container = styled.div`
  background-color: ${Shade00Color};
  display: flex;
  gap: 16px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

// TODO: Switch to common Link component when it is built out.
const Link = styled.a`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: ${Shade70Color};
`;

// TODO: Switch P3 to Link when Link is built out. For now they are almost the same
const ProviderDetails = React.forwardRef(
  (
    {
      address,
      className,
      email,
      name,
      occupation,
      department,
      phoneNumber,
      src,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Container className={className} ref={ref}>
        <Avatar alt={name} src={src} />
        <DetailsContainer>
          <H5 bold>{name}</H5>
          <H5>{occupation}</H5>
          {department && <P3 bold>{department}</P3>}
          {email && <Link href={formatForEmail(email)}>{email}</Link>}
          {phoneNumber && (
            <Link href={formatForTelephone(phoneNumber)}>{phoneNumber}</Link>
          )}
          {address && (
            <Link
              href={formatForAddress(address)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {address}
            </Link>
          )}
        </DetailsContainer>
      </Container>
    );
  }
);

export default ProviderDetails;
