/**
 * Clickable text that is used to navigate to a new page, section on a page, or trigger an action
 * such as open a modal.
 * When used in a paragraph, apply using the typography styles instead of a component.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1850%3A1065
 */

import React, { ForwardedRef } from 'react';
import styled from 'styled-components';
import { Shade70Color } from '../../styles/common';

interface Props {
  /**
   * The content of the component.
   */
  children: string;

  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * Color used on hover.
   * @default inherit
   */
  hoverColor?: string;

  /**
   * OnClick handler for the component.
   */
  onClick?: () => void;

  /**
   * It is a DOMString containing a space-separated list of link types
   * indicating the relationship between the resource represented by the
   * <a> element and the current document.
   */
  rel?: string;

  /**
   * If true, the font-size will be smaller.
   * @default false
   */
  small?: boolean;

  /**
   * The target of the link.
   * Can be used to open a new tab instead of replacing the current one.
   *
   * @example _blank
   */
  target?: string;

  /**
   * The destination of the link.
   * @example https://www.notablehealth.com
   */
  to?: string;
}

const StyledLink = styled.a.attrs(
  (props: { $hoverColor: string; $small: boolean }) => ({
    $hoverColor: props.$hoverColor,
    $small: props.$small,
  })
)`
  color: ${Shade70Color};

  font-weight: 600;
  font-size: ${props => (props.$small ? '0.875rem' : '1rem')};
  line-height: ${props => (props.$small ? '1.125rem' : '1.25rem')};

  text-underline-offset: 2px;

  &:hover {
    color: ${props => props.$hoverColor};
  }
`;

const Link = React.forwardRef(
  (
    { children, hoverColor = 'inherit', small = false, to, ...rest }: Props,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    return (
      <StyledLink $small={small} href={to} {...(rest as any)} ref={ref}>
        {children}
      </StyledLink>
    );
  }
);

export default Link;
