import React, { useState } from "react";
import Confirm from "../../ui/src/components/Confirm";
import "../../styles/recommendations.scss";
import { P3, H4, H5 } from "../../ui/src/components/Typography";
import Button from "../../ui/src/components/Button";
import { useTheme } from "../../Theme";

import { ReactComponent as VerifyIcon } from "../../assets/verify_icon.svg";

import plusIcon from "../../assets/plus_icon.svg";
import carIcon from "../../assets/rideshare_icon.svg";
import heartIcon from "../../assets/heart_icon.svg";
import heartbeatIcon from "../../assets/heartbeat_icon.svg";
import bandageIcon from "../../assets/bandage_icon.svg";

const RecommendationsModalContent = (props) => {
  const [selected, updateSelected] = useState([0, 0, 0, 1, 0]);
  const [showPopup, setIsOpen] = useState(false);
  const handleClick = (index) => {
    setIsOpen(true);
    updateSelected((prevState) =>
      prevState.map((el, i) => (i === index ? 1 - el : el))
    );
  };

  const { theme } = useTheme();

  const renderButton = (i) => (
    <>
      <Button
        variant="outlined"
        disabled={selected[i]}
        fullWidth={true}
        onClick={() => handleClick(i)}
        color={theme.primaryColor}
      >
        {selected[i] ? <>Benefit accepted</> : <>Yes, I'm interested</>}
      </Button>
      {selected[i] ? (
        <P3 bold={true} className="follow-up">
          We will be following up shortly
        </P3>
      ) : null}
    </>
  );

  return (
    <div className="recommendations-modal-content">
      <Confirm
        icon={<VerifyIcon />}
        isOpen={showPopup}
        title="We will reach out shortly"
        bodyText="Someone from our staff will follow up with next steps on your care recommendation."
        confirmHandler={() => setIsOpen(false)}
        confirmText="Continue"
        themeColor={theme.primaryColor}
      />
      <H4 bold={true} className="header">
        We’ve identified the following additional recommendations that you are
        not currently taking advantage of.{" "}
      </H4>
      <div className="card">
        <H4 bold={true} className="header">
          Care recommendations
        </H4>
        <div className="subcard">
          <div className="header">
            <img src={heartIcon} alt="clinical trial card icon" />
            <H4 bold={true}>Join our new clinical trial</H4>
          </div>
          <H5>
            We are partnering in a new clinical trial for remote blood sugar
            monitoring. Would you like to learn more & enroll?
          </H5>
          {renderButton(2)}
        </div>
        <div className="subcard">
          <div className="header">
            <img src={heartbeatIcon} alt="diabetes card icon" />
            <H4 bold={true}>Improved diabetes management</H4>
          </div>
          <H5>
            We offer free diabetes management tools through our partner Omada
            health. Would you like to learn more about this program?
          </H5>
          {renderButton(3)}
        </div>
        <div className="subcard">
          <div className="header">
            <img src={bandageIcon} alt="med management icon" />
            <H4 bold={true}>Easier medication management</H4>
          </div>
          <H5>
            We noticed you are still using a physical location for your
            pharmacy, would you like to have us update your prescriptions to be
            sent via USPS for no extra charge?
          </H5>
          {renderButton(4)}
        </div>
      </div>
      <div className="card">
        <H4 bold={true} className="header">
          Appointment recommendations
        </H4>
        {/* <div className="subcard">
          <div className="header">
            <img src={carIcon} alt="rideshare card icon" />
            <H4 bold={true}>Free rideshare available</H4>
          </div>
          <H5>
            Because you’re on Medicaid, you’re eligible for free transportation
            to your appointments through our rideshare partner. Would you like
            us to schedule a free ride to your next appointment on{" "}
            <span className="bold">Sep 10, 1:30pm?</span>
          </H5>
          {renderButton(0)}
        </div> */}
        <div className="subcard">
          <div className="header">
            <img src={plusIcon} alt="consolidation icon" />
            <H4 bold={true}>Appointment consolidation</H4>
          </div>
          <H5>
            Next week, you have 2 appointments on different days. Would you like
            to be put on the waitlist to combine them together?
          </H5>
          {renderButton(1)}
        </div>
      </div>
    </div>
  );
};

export default RecommendationsModalContent;
