import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Review from "./pages/Review";
import Registration from "./pages/Registration";
import Layout from "./pages/Layout";
import { createBrowserRouter, Navigate } from "react-router-dom";

const redirectToDefaultUser = () => {
  const params = new URLSearchParams(window.location.search);
  if (!params.get("user")) {
    params.set("user", "default");
  }
  return `/signup?${params.toString()}`;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate replace to={redirectToDefaultUser()} />,
      },
      {
        path: "/signup",
        element: <Auth />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/review",
        element: <Review />,
      },
      { path: "/registration", element: <Registration /> },
    ],
  },
]);

export default router;
