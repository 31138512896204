import { createContext } from 'react';
import { Props as ToastProps } from './Toast';

export interface Context {
  open: (
    message: string,
    options?: Partial<Omit<ToastProps, 'onClose' | 'message'>>
  ) => void;
}

const ToastContext = createContext<Context>({} as Context);
export default ToastContext;
