import React, { useState } from "react";
import { P3, H4, H5 } from "../../ui/src/components/Typography";
import Button from "../../ui/src/components/Button";
import Confirm from "../../ui/src/components/Confirm";
import { ReactComponent as VerifyIcon } from "../../assets/verify_icon.svg";

import { useTheme } from "../../Theme";

import "../../styles/recommendation.scss";

import diabetesRecIcon from "../../assets/diabetes_rec_icon.svg";
const DiabetesRecommendationContent = (props) => {
  const { setIsOpen } = props;
  const [showPopup, setShowPopup] = useState(false);
  const { theme } = useTheme();
  return (
    <div className="recommendation-content">
      <Confirm
        icon={<VerifyIcon />}
        isOpen={showPopup}
        title="We will reach out shortly"
        bodyText="Someone from our staff will follow up with next steps on your care recommendation."
        confirmHandler={() => setIsOpen(false)}
        confirmText="Continue"
        themeColor={theme.primaryColor}
      />
      <img src={diabetesRecIcon} alt="icon" width="120px" />
      <H4 bold={true}>A diabetes program that adapts to every member</H4>
      <H5 bold={true}>A unique plan for you</H5>
      <P3>
        We’ll help you build healthy routines around what you love to do, and
        where you need the most guidance.
      </P3>
      <H5 bold={true}>Ongoing personal support</H5>
      <P3>
        Your Certified Diabetes Care and Education Specialist (CDCES) actually
        gets to know you and is there for you.
      </P3>
      <H5 bold={true}>A science-based approach</H5>
      <P3>
        We’ll show you better ways to manage your medications and keep your
        blood sugar levels in check.
      </P3>
      <div className="button-group">
        <Button
          fullWidth={true}
          onClick={() => setShowPopup(true)}
          color={theme.primaryColor}
        >
          Yes, I'm interested
        </Button>
        <Button
          fullWidth={true}
          variant="outlined"
          onClick={() => setIsOpen(false)}
          color={theme.primaryColor}
        >
          Not interested
        </Button>
      </div>
    </div>
  );
};

export default DiabetesRecommendationContent;
