import React from "react";
import Modal from "react-modal";

import Button from "../Button";
import { H4, P2 } from "../Typography";
import "./confirm.scss";

interface Props {
  /**
   * Controlled state boolean passed to Modal component to control rendering
   */
  isOpen: boolean;

  /**
   * Optional icon as ReactComponent
   */
  icon?: string;

  /**
   * Header text
   */
  title: string;

  /**
   * Optional additional text
   */
  bodyText?: string;

  /**
   * Text for confirm button
   */
  confirmText: string;

  /**
   * Text for dismiss button
   */
  dismissText: string;

  /**
   * onClick handler for confirm button. Should manipulate isOpen value
   */
  confirmHandler: () => unknown;

  /**
   * onClick handler for dismiss button. Should manipulate isOpen value
   */
  dismissHandler: () => unknown;

  /**
   * optional color descriptor for theming
   */
  themeColor?: string;
}

const Popup: React.FC<Props> = (props) => {
  const {
    isOpen,
    confirmText,
    dismissText,
    icon,
    confirmHandler,
    dismissHandler,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={dismissHandler}
      className="confirm-modal"
      overlayClassName="confirm-overlay"
      closeTimeoutMS={300}
    >
      <div className="confirm-wrapper">
        {icon && icon}
        <H4>{props.title}</H4>
        <P2>{props.bodyText}</P2>
        <Button
          fullWidth={true}
          onClick={() => confirmHandler()}
          color={props.themeColor}
        >
          {confirmText}
        </Button>
        {dismissText && (
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={() => props.dismissHandler()}
            color={props.themeColor}
          >
            {dismissText}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default Popup;
