import React, { useState, useEffect } from "react";
import { P2, H4, H5 } from "../../ui/src/components/Typography";
import { DataCard } from "../../ui/src/components/Card";
import { Button } from "../../ui/src/components";
import Camera from "../../ui/src/components/Camera";
import { useTheme } from "../../Theme";
import Modal from "../../ui/src/components/Modal";

import { VIEW_CARD, VIEW_CARDS, PROCESSING } from "../../constants";

import insurance_1_logo from "../../assets/insurance_1.svg";
import insurance_2_logo from "../../assets/insurance_2.svg";
import verifyIcon from "../../assets/verify_icon.svg";

import "../../styles/insurance.scss";
import Loading from "../Loading";

const ReviewInsuranceModalContent = (props) => {
  const [step, setStep] = useState(VIEW_CARD);

  const cards = [
    {
      logo: insurance_2_logo,
      data: [
        { key: "Subscriber", value: "Samantha Smith" },
        { key: "Member ID", value: "0300000002" },
        { key: "Group number", value: "00884700" },
        { key: "Coverage start date", value: "01/01/2022" },
      ],
    },
    {
      logo: insurance_1_logo,
      data: [
        { key: "Subscriber", value: "Samantha Smith" },
        { key: "Member ID", value: "BS1234567890" },
        { key: "Group number", value: "24" },
        { key: "Coverage start date", value: "10/12/2020" },
      ],
    },
  ];
  const stepComponentMap = {
    VIEW_CARD: <ViewCard card={cards[1]} setStep={setStep} />,
    // CAMERA: <CameraCard setStep={setStep} />,
    PROCESSING: <ProcessingCard setStep={setStep} />,
    VIEW_CARDS: (
      <ViewCards
        cards={cards}
        closeModal={props.closeModal}
        handleUpdate={props.handleUpdate}
        modalArgs={props.modalArgs}
      />
    ),
  };

  return <div className="insurance-modal">{stepComponentMap[step]}</div>;
};

const ViewCard = (props) => {
  const { card, setStep } = props;
  const { theme } = useTheme();
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Front of insurance card");

  return (
    <>
      <Modal
        modalIsOpen={showCameraModal}
        closeModal={() => setShowCameraModal(false)}
        title={modalTitle}
        partial={true}
        overlayClassName="camera-overlay"
        modalClassName="camera-modal"
      >
        <CameraModalContent
          setStep={setStep}
          closeModal={() => setShowCameraModal(false)}
          setModalTitle={setModalTitle}
        />
      </Modal>
      <H4 bold={true}>
        Here’s the card we have on file. Would you like to add any additional
        insurance cards?
      </H4>
      <div className="insurance-card-wrapper">
        <DataCard
          className="insurance-card"
          data={card?.data}
          header={<img src={card?.logo} alt="insurance provider logo" />}
        />
      </div>
      <div className="button-group">
        <Button
          color={theme.primaryColor}
          onClick={() => setShowCameraModal(true)}
        >
          Yes
        </Button>
        <Button color={theme.primaryColor} variant="outlined">
          No
        </Button>
      </div>
    </>
  );
};

const CameraModalContent = (props) => {
  const { setStep, closeModal, setModalTitle } = props;
  const [side, setSide] = useState(0);
  const [loading, setLoading] = useState(false);
  const SIDE_STRINGS = ["front", "back"];
  const { theme } = useTheme();

  const completeModal = () => {
    closeModal();
    setStep(PROCESSING);
  };

  const goToBack = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSide(1);
      setModalTitle("Back of insurance card");
    }, 1000);
  };

  return (
    <>
      <div className="camera-wrapper">
        <Camera className="camera" />
      </div>
      <div className="content-wrapper">
        {loading ? (
          <Loading color={theme.primaryColor} />
        ) : (
          <>
            <P2 color="white">
              Place the {SIDE_STRINGS[side]} of your insurance card in the box
              above
            </P2>
            <Button
              color={theme.primaryColor}
              fullWidth={true}
              onClick={() => {
                side === 0 ? goToBack() : completeModal();
              }}
            >
              Capture {SIDE_STRINGS[side]} of card
            </Button>
          </>
        )}
      </div>
    </>
  );
};

const ProcessingCard = (props) => {
  const { setStep } = props;
  const [stage, setStage] = useState(0);
  const { theme } = useTheme();
  useEffect(() => {
    // setStage = processing card internal animations
    // setStep = modal pages
    setTimeout(() => {
      setStage(1);
    }, 1500);
    setTimeout(() => {
      setStage(2);
    }, 3000);
    setTimeout(() => {
      setStep(VIEW_CARDS);
    }, 4500);
  }, []);

  return (
    <div>
      <H4 bold={true}>
        Verifying eligibility and adding your new insurance card.
      </H4>
      <div className="processing-card">
        {stage === 0 && (
          <>
            <H4 bold={true}>Processing card</H4>
            <div className="progress-bar-wrapper">
              <div
                style={{ backgroundColor: theme.primaryColor }}
                className="inner"
              ></div>
            </div>
          </>
        )}
        {stage === 1 && (
          <>
            <H4 bold={true}>Verifying eligibility</H4>
            <div className="progress-bar-wrapper">
              <div
                style={{ backgroundColor: theme.primaryColor }}
                className="inner"
              ></div>
            </div>
          </>
        )}
        {stage === 2 && (
          <>
            <img
              src={verifyIcon}
              className="checkmark"
              alt="green check mark icon"
            />
            <H4 bold={true}>Verification successful</H4>
          </>
        )}
      </div>
    </div>
  );
};

const ViewCards = (props) => {
  const { cards, closeModal, handleUpdate, modalArgs } = props;
  const { theme } = useTheme();

  const dismiss = () => {
    handleUpdate(...Object.values(modalArgs));
    closeModal();
  };

  return (
    <>
      <H4 bold={true}>
        We were able to verify your coverage & add your card to your profile.
        Continue with this insurance or add another card.
      </H4>
      <div className="insurance-card-wrapper">
        {cards?.map((card, i) => (
          <DataCard
            className="insurance-card"
            data={card?.data}
            header={
              <>
                <img src={card?.logo} alt="insurance provider logo" />
                {i === 0 && (
                  <H5 bold={true} color="#08A966">
                    New Card
                  </H5>
                )}
              </>
            }
          ></DataCard>
        ))}
      </div>

      <div className="button-group">
        <Button color={theme.primaryColor} onClick={dismiss}>
          Continue with this insurance
        </Button>
        <Button color={theme.primaryColor} variant="outlined">
          Add another card
        </Button>
      </div>
    </>
  );
};

export default ReviewInsuranceModalContent;
