import React from "react";
import "../styles/loading.scss";

const Loading = (props) => {
  return (
    <div
      className="loader dot-pulse"
      // style={{ backgroundColor: props.color, color: props.color }}
    ></div>
  );
};

export default Loading;
