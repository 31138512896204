import React, { useState, useMemo } from "react";
import { createPortal } from "react-dom";

import ToastContext from "./ToastContext";
import Toast, { Props as ToastProps } from "./Toast";

import "./toast.scss";

type ToastProviderToast = { id: string; message: string } & Partial<
  Omit<ToastProps, "onClose" | "message">
>;

//@ts-ignore
const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProviderToast[]>([]);

  const open = (
    message: string,
    options?: Partial<Omit<ToastProps, "onClose" | "message">>
  ) => {
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: Math.random().toString(), message, ...options },
    ]);
  };

  const close = (id: string) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );

  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}

      {createPortal(
        <div className="toasts-wrapper">
          {toasts.map(({ id, message, ...rest }) => (
            <Toast
              key={id}
              onClose={() => close(id)}
              message={message}
              {...rest}
            />
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
