import React, { useState } from "react";
import VerificationInput from "react-verification-input";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

import Modal from "../ui/src/components/Modal/Modal";
import poweredBy from "../assets/powered_by.svg";
import notableCopy from "../assets/notable_copy.svg";

import Button from "../ui/src/components/Button/Button";
import { H3, H4, H5, P3 } from "../ui/src/components/Typography";
import Link from "../ui/src/components/Link";

import "../styles/auth.scss";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";
import Checkbox from "../components/Checkbox";

import { useTheme } from "../Theme";

const VALID_AUTH_CODE = "4444";
const ERROR_COLOR = "#EA3F3F";

const Auth = () => {
  const [selected, setSelected] = useState(true);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const check2faValidity = (e) => {
    if (!e) return;

    if (e.length > 4) {
      setError("Auth code is too long.");
    } else if (e.length === 4 && e !== VALID_AUTH_CODE) {
      setError("Auth code is incorrect.");
    } else if (e.length === 4) {
      setError("");
      setLoading(true);

      setTimeout(() => {
        navigate("/home?status=default");
        setIsOpen(false);
      }, 1000);
    }
  };

  const { theme } = useTheme();

  return (
    <>
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsOpen(false)}
        title="Verify your phone number"
      >
        <div className="phone-num-verification">
          <H4 bold={true}>Add the 4-digit code sent to ***-**55</H4>
          {loading ? (
            <div className="loader">
              <Loading color={theme.primaryColor} />
            </div>
          ) : (
            <>
              <VerificationInput
                validChars="0-9"
                inputProps={{ type: "tel" }}
                length={4}
                placeholder=""
                autoFocus={true}
                classNames={{
                  container: "container",
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
                onChange={check2faValidity}
              />
              {error && (
                <H5 className="error" color={ERROR_COLOR}>
                  {error}
                </H5>
              )}
              <H4
                bold={true}
                className="resend-link"
                color={theme.primaryColor}
              >
                Didn’t receive a code? Click here.
              </H4>
            </>
          )}
        </div>
      </Modal>
      <div className="auth-page">
        <div className="intro-section">
          <H3>Hello, Samantha. Let's pull up your patient details.</H3>
        </div>
        <div className="form-section">
          <div className="form-group">
            <div>
              <label>
                <H4 bold={true}>Enter your last name</H4>
              </label>
            </div>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e?.target?.value)}
            ></input>
          </div>
          <div className="form-group">
            <div>
              <label>
                <H4 bold={true}>Enter your date of birth</H4>
              </label>
            </div>
            <MaskedInput
              type="text" // TODO: Make this default to numbers instead of text onFocus
              placeholder="MM / DD / YYYY"
              showMask={false}
              guide={false}
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              mask={[
                /\d/,
                /\d/,
                " ",
                "/",
                " ",
                /\d/,
                /\d/,
                " ",
                "/",
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
            />
          </div>
          <div className="form-group">
            <Checkbox
              label={<H5>Remember me for 30 days</H5>}
              checked={selected}
              onClick={(val) => setSelected(!selected)}
            />
          </div>
          <div className="form-group">
            <Button
              type="submit"
              fullWidth={true}
              onClick={() => setIsOpen(true)}
              disabled={name && dob ? false : true}
              color={theme.primaryColor}
            >
              Sign in
            </Button>
          </div>
        </div>
        <div className="footer-section">
          <Button variant="text" color="#6C6E88" className="help-link">
            Need help? Click here for support
          </Button>
          <P3 className="disclaimer">
            By signing in, you agree to our{" "}
            <Link color={theme.primaryColor} small={true}>
              Terms of Use.
            </Link>{" "}
            Use of this tool is for non-urgent communications only. Responses
            will not be reviewed until your visit.
          </P3>
          <div className="logo-subsection">
            <div>
              <img src={poweredBy} alt="powered by" />
            </div>
            <div>
              <img src={notableCopy} alt="notbale" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
