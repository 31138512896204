/**
 * Avatars are typically used when you are referencing a provider, typically in association with an appointment.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1862%3A1203
 */

import React from "react";
import styled from "styled-components";

import { Shade30Color, Shade50Color, Shade70Color } from "../../styles/common";
import { ReactComponent as UserSvg } from "../../assets/user.svg";

interface Props {
  /**
   * An alt attribute for the rendered img element.
   * Required for accessibility.
   */
  alt: string;

  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * The src attribute for the img element.
   */
  src?: string;
}

const ImageWrapper = styled.div.attrs((props: { $useDefault: boolean }) => ({
  $useDefault: props.$useDefault,
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  height: 48px;
  width: 48px;

  border: 1px solid ${Shade50Color};
  border-radius: 50%;

  background-color: ${(props) =>
    props.$useDefault ? Shade30Color : "inherit"};
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  text-align: center;
  user-select: none;
`;

const DefaultAvatar = styled(UserSvg)`
  color: ${Shade70Color};
`;

const Avatar = React.forwardRef(({ alt, className, src }: Props, ref) => {
  const useDefault = src === undefined;
  return (
    <ImageWrapper
      className={className}
      ref={ref as any}
      $useDefault={useDefault}
    >
      {src ? <Image src={src} alt={alt} role="img" /> : <DefaultAvatar />}
    </ImageWrapper>
  );
});

export default Avatar;
