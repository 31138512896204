import { useState, useRef } from "react";
import Measure from "react-measure";
import { useUserMedia } from "./useUserMedia";
import { useCardRatio } from "./useCardRatio";
import { useOffsets } from "./useOffsets";
import { Video, Canvas, Wrapper, Container, Flash, Overlay } from "./styles";
import Button from "../Button/Button";
import _ from "lodash";

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" },
};

interface Props {
  onCapture: (blob: Blob | null) => void;
  onClear: () => void;
}

export function Camera({ onCapture, onClear }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>();
  const videoRef = useRef<HTMLVideoElement>();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  function handleResize(contentRect: any) {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / (aspectRatio as number)),
    });
  }

  function handleCanPlay() {
    if (videoRef.current) {
      // @ts-ignore
      calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
      setIsVideoPlaying(true);
      videoRef.current.play();
    }
  }

  function handleCapture() {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext(
        "2d"
      ) as CanvasRenderingContext2D;

      context.drawImage(
        videoRef.current,
        offsets.x,
        offsets.y,
        container.width,
        container.height,
        0,
        0,
        container.width,
        container.height
      );

      canvasRef.current.toBlob((blob) => onCapture(blob), "image/jpeg", 1);
      setIsCanvasEmpty(false);
      setIsFlashing(true);
    }
  }

  function handleClear() {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext(
        "2d"
      ) as CanvasRenderingContext2D;
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      setIsCanvasEmpty(true);
      onClear();
    }
  }

  if (!mediaStream) {
    return null;
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }: any) => (
        <Wrapper>
          <Container
            ref={measureRef}
            maxHeight={videoRef.current && videoRef.current.videoHeight}
            maxWidth={videoRef.current && videoRef.current.videoWidth}
            style={{
              height: `${container.height}px`,
            }}
          >
            <Video
              ref={videoRef as any}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              style={{
                top: `-${offsets.y}px`,
                left: `-${offsets.x}px`,
              }}
            />

            <Overlay hidden={!isVideoPlaying} />

            <Canvas
              ref={canvasRef as any}
              width={container.width}
              height={container.height}
            />

            <Flash
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />
          </Container>

          {isVideoPlaying && (
            <div
              style={{ width: "100%", marginTop: "24px" }}
              className="button-wrapper"
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={isCanvasEmpty ? handleCapture : handleClear}
              >
                {isCanvasEmpty ? "Take a picture" : "Take another picture"}
              </Button>
            </div>
          )}
        </Wrapper>
      )}
    </Measure>
  );
}

export default Camera;
