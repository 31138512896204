/**
 * When we process a patient uploaded card, we take the image that the patient
 * uploaded, run OCR (Optical character recognition) on the card to pull the
 * relevant fields, and then process those fields using
 * RTE (Real-time eligibility).
 *
 * Once this process is completed, we provide a digital representation of that
 * card for the patient. Different payers have different fields, so this
 * component is meant to be flexible in the # of lines it can present, and also
 * show the payers logo dynamically. If no logo is present, we use type to
 * represent the payer. The two variants here are used for different formats in
 * demos.
 *
 * These cards always span to the full available width of the container.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1812%3A1330
 */

import React, { ForwardedRef, ReactNode } from 'react';
import styled from 'styled-components';
import {
  Shade00Color,
  Shade20Color,
  Shade30Color,
  Shade50Color,
} from '../../../styles/common';
import { H5 } from '../../Typography';
import Button from '../../Button';

interface DataCardData {
  /**
   * The data point label.
   * @example "Plan Name"
   */
  key: string;

  /**
   * The value of the data point.
   * @example "Kaiser Medicare"
   */
  value: string;
}

interface DataCardHeaderAction {
  /**
   * The button label.
   */
  label: string;

  /**
   * On click handler for the button.
   */
  onClick: () => void;
}

interface Props {
  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * An array of data points to display in the card.
   */
  data: DataCardData[];

  /**
   * The content to display on the left side of the header.
   * Should usually be a company logo or a typography component.
   */
  header: ReactNode;

  /**
   * Props to pass to the header action button.
   * Omitting this prop will remove the button from view.
   */
  headerAction?: DataCardHeaderAction;
}

const Container = styled.div`
  border: 1px solid ${Shade50Color};
  border-radius: 4px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  padding: 12px 16px;
  background-color: ${Shade20Color};
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ContentContainer = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  padding: 16px;

  background-color: ${Shade00Color};
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  white-space: nowrap;

  & > :last-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

/**
 * NOTE: Use `div` instead of `hr` because `hr` doesn't display correctly.
 * Make sure to add `role="separator"` to any usage for accessibility.
 */
const Divider = styled.div`
  border: none;
  height: 1px;
  color: ${Shade30Color};
  background-color: ${Shade30Color};
  margin: 4px 0;
`;

const DataCard = React.forwardRef(
  (
    { className, data, headerAction, header }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Container className={className} ref={ref}>
        <HeaderContainer>
          {header}
          {headerAction && (
            <Button plain size="small" onClick={headerAction.onClick}>
              {headerAction.label}
            </Button>
          )}
        </HeaderContainer>
        <ContentContainer>
          {data.map(({ key, value }, i) => (
            <React.Fragment key={key}>
              <ContentRow>
                <H5>{key}</H5>
                <H5>{value}</H5>
              </ContentRow>
              {i !== data.length - 1 && <Divider role="separator" />}
            </React.Fragment>
          ))}
        </ContentContainer>
      </Container>
    );
  }
);

export default DataCard;
