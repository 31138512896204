/**
 * These are used within the patient experience, as well as emails sent to
 * patients to display their appointment information.
 *
 * We use this card within confirm/cancel/reschedule experience, as a summary of
 * the appointment at the end of the formset experience, and can be re-used
 * anywhere that we need to give a visual reminder to a patient of their
 * appointment.
 *
 * This component is meant to be flexible, in that you can add/remove the
 * individual elements within the card where needed.
 * Example: Confirm/cancel/reschedule is a much more consolidated version in a
 * longer list, and the formset summary is a full-detail version because we have
 * more space to work with.
 *
 * Some card types can contain a state or status, such as showing if an
 * appointment was rescheduled or canceled, but this wouldn’t be present in the
 * full-detail card since it’s shown at the end of a formset completion. When
 * the alert is present, hide the link container.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1812%3A1330
 */

import moment from "moment";
import React from "react";

import Badge from "../ui/src/components/Badge/Badge.tsx";
import ProviderDetails from "../ui/src/components/ProviderDetails/ProviderDetails.tsx";

import "../ui/src/styles/appointment-card.scss";

const AppointmentCard = React.forwardRef(
  (
    {
      arrivalTime,
      badgeProps,
      children,
      className,
      datetime,
      providerDetailsProps,
    },
    ref
  ) => {
    const classes = ["appointment-card", className].filter(Boolean).join(" ");

    const renderBadges = (badgeProps) => {
      return (!Array.isArray(badgeProps) ? [badgeProps] : badgeProps).map(
        (props, i) => <Badge {...props} key={i} />
      );
    };

    return (
      <div className={classes} ref={ref}>
        <div className="header">
          <div className="badges">{badgeProps && renderBadges(badgeProps)}</div>
          <div className="title-wrapper">
            <span className="bold">
              {moment(datetime).format("MMM D, h:mma")}
            </span>
            {arrivalTime && (
              <span className="arrival-time">
                (Arrive: {moment(arrivalTime).format("h:mma")})
              </span>
            )}
          </div>
        </div>
        <ProviderDetails {...providerDetailsProps} />
        {children}
      </div>
    );
  }
);

export default AppointmentCard;
