import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.scss";

// import { ReactComponent as Arrow } from "../assets/arrow.svg";
import { ReactComponent as Arrow } from "../ui/src/assets/arrow-up.svg";

// carat
import arrow_down from "../assets/arrow_down.svg";

import { useNavigate } from "react-router-dom";

import { H5 } from "../ui/src/components/Typography";

import { useTheme } from "../Theme";

const Navbar = (props) => {
  const [logo, setLogo] = useState(null);
  const navigate = useNavigate();
  const isAuthPage = window.location.href.includes("signup");

  const { theme } = useTheme();

  useEffect(() => {
    if (theme) {
      try {
        let _logo = require(`../assets/logos/${theme.slug}.png`);
        setLogo(_logo);
      } catch (e) {
        console.log("missing client logo");
        let _logo = require("../assets/logos/default.png");
        setLogo(_logo);
      }
    }
  }, [false]);

  const navigateBack = () => {
    if (!isAuthPage) {
      navigate(-1);
      // window.scroll(0, 0);
    }
  };

  return (
    <header className="navbar">
      <div>
        <Arrow
          stroke={theme.primaryColor}
          className={isAuthPage ? "arrow inactive" : "arrow"}
          onClick={() => navigateBack()}
        />
      </div>
      <div>
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="lang-selector">
        <H5>Eng </H5>
        <img src={arrow_down} alt="arrow" />
      </div>
    </header>
  );
};

export default Navbar;
