import React from "react";
import { useTheme } from "../Theme";
import styled from "styled-components";
import "../styles/checkbox.scss";

const Radio = (props) => {
  const { checked, onChange } = props;
  const { theme } = useTheme();
  return (
    <StyledRadio theme={theme}>
      <input
        type="radio"
        value="credit"
        name="payment"
        checked={checked}
        onChange={onChange}
      />
    </StyledRadio>
  );
};

export default Radio;

const StyledRadio = styled.div`
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: ${(p) => p?.theme?.primaryColor};
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${(p) => p?.theme?.primaryColor};
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid ${(p) => p?.theme?.primaryColor};
    outline-offset: max(2px, 0.15em);
  }
`;
