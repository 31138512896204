/**
 * We use alerts within all of our apps, and have some slightly different methods of applying them to the product.
 * However, the styling of all alerts follow the same formats regardless of which app they are being used in.
 *
 * Informational alerts are used to display supplimentary information to help support a header with additional context,
 * or give additional information on the expected behavior of a feature.
 * Treat these as “low priority”, and use them when you don’t need to go into as much depth as a contextual helper.
 * These alerts always fill the full container space available.
 * The icon should be aligned with the top line of text, unless the text only spans 1 line.
 * When the text only spans 1 line, it should be aligned vertically with the icon.
 * Info alerts use the same icon for every instance.
 *
 * These medium priority alerts are used when there is a need to increase visual attention to the alert.
 * They will typically be associated with components that may have an impact on the user.
 * For example: Saving an update on an item that will impact other items down the chain.
 *
 * Danger alerts are the highest priority alerts, and are used sparingly.
 * These alerts are really meant to provide context for actions that will have a large impact on the user.
 * For example: Deleting an item that will not be reversible.
 *
 * @figma https://www.figma.com/file/LdhxwbVqKRRLpyK5ekr4Q8/Component-Library?node-id=1812%3A904
 */
import React from "react";
import styled, { css } from "styled-components";

import { H5 } from "../Typography";

import { ReactComponent as DangerSvg } from "../../assets/danger.svg";
import { ReactComponent as InfoSvg } from "../../assets/info-v2.svg";
import { ReactComponent as WarningSvg } from "../../assets/warning.svg";

import {
  ErrorStateColor,
  PrimaryBrandColor,
  Shade50Color,
  Shade60Color,
  WarningStateColor,
} from "../../styles/common";

import { parseError } from "../../utils/errors";
import { alpha } from "../../utils/colorManipulator";

export type AlertVariant = "info" | "warning" | "danger";

interface AlertCta {
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;

  /**
   * The label for the CTA.
   */
  label: string;

  /**
   * The action to take when clicking on the CTA.
   */
  onClick: () => void;
}

interface Props {
  /**
   * Classes applied to the root element.
   */
  className?: string;

  /**
   * Call to action object props.
   */
  cta?: AlertCta;

  /**
   * An error to display as the main text.
   * Passing an error implies `variant="danger"`.
   */
  error?: Error;

  /**
   * Hides the alert icon, if true.
   * @default false
   */
  hideIcon?: boolean;

  /**
   * Plain alerts don't have an outline or background.
   * Should not be used with a title or CTA.
   * @default false
   */
  plain?: boolean;

  /**
   * The title text to display in the Alert.
   */
  title?: string;

  /**
   * The alert variant to use.
   * @default "info"
   */
  variant?: AlertVariant;

  children?: React.ReactNode;
}

const Alert: React.FC<Props> = ({
  children,
  className,
  cta,
  error,
  hideIcon = false,
  plain = false,
  title,
  variant = "info",
}) => {
  const hasError = !!error;
  const _variant: AlertVariant = hasError ? "danger" : variant;
  const getIcon = () => {
    switch (_variant) {
      case "warning":
        return WarningSvg;
      case "danger":
        return DangerSvg;
      case "info":
        return InfoIcon;
    }
  };

  const Icon = getIcon();

  return (
    <Container
      className={className}
      plain={plain}
      role="alert"
      variant={_variant}
    >
      {!hideIcon && (
        <IconContainer>
          <Icon width={24} height={24} />
        </IconContainer>
      )}
      <TextContainer>
        {title && <Title bold>{title}</Title>}
        <H5>{hasError ? parseError(error) : children ?? ""}</H5>
        {cta && (
          <CTA
            onClick={cta.disabled ? undefined : cta.onClick}
            $disabled={cta.disabled}
          >
            {cta.label}
          </CTA>
        )}
      </TextContainer>
    </Container>
  );
};

const BorderColor: Record<AlertVariant, string> = {
  info: Shade50Color,
  warning: WarningStateColor,
  danger: ErrorStateColor,
} as const;

const Container = styled.div.attrs(
  (props: { plain: boolean; variant: AlertVariant }) => ({
    plain: props.plain,
    variant: props.variant ?? "info",
  })
)`
  ${(props) =>
    !props.plain &&
    css`
      background-color: ${alpha(BorderColor[props.variant], 0.04)};
      border: 1px solid ${BorderColor[props.variant]};
      border-radius: 4px;
      min-width: 250px;
    `}

  display: flex;
  padding: ${(props) => (props.plain ? "4px 0" : "12px 16px")};
  width: 100%;
`;

const IconContainer = styled.div`
  margin-right: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
`;

const Title = styled(H5)`
  margin-bottom: 4px;
`;

const CTA = styled(Text).attrs((props: { $disabled: boolean }) => ({
  $disabled: props.$disabled ?? false,
}))`
  color: ${(props) => (props.$disabled ? Shade60Color : PrimaryBrandColor)};
  cursor: ${(props) => (props.$disabled ? "default" : "pointer")};
  font-weight: 600;
  margin-top: 12px;
  width: fit-content;

  ${(props) =>
    !props.$disabled &&
    css`
      &:hover {
        text-decoration: underline;
        text-underline-offset: 1px;
      }
    `}
`;

const InfoIcon = styled(InfoSvg)`
  color: ${Shade60Color};
`;

export default Alert;
