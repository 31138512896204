import React, { useState, useEffect } from "react";
import "../styles/drawer.scss";
import close from "../ui/src/assets/close.svg";
import { H4 } from "../ui/src/components/Typography";

const Drawer = (props) => {
  const {
    title,
    setModalIsOpen,
    dismissDrawer,
    dismissTimeout,
    onAfterOpen,
    onAfterClose,
  } = props;

  const [entering, setEntering] = useState(false);

  useEffect(() => {
    setEntering(true);
  }, []);

  // execute mount/unmount handlers if provided
  useEffect(() => {
    onAfterOpen && onAfterOpen();

    return () => {
      onAfterClose && onAfterClose();
    };
  }, []);

  useEffect(() => {
    if (dismissTimeout) {
      autoDismiss(dismissTimeout);
    }
  }, []);

  const autoDismiss = (timeout) => {
    setTimeout(() => {
      dismissDrawer();
    }, timeout);
  };

  const handleClick = () => {
    dismissDrawer();
    setModalIsOpen(true);
  };

  const handleDismiss = () => {
    setEntering(false);
    setTimeout(() => {
      dismissDrawer();
    }, 3000);
  };

  return (
    <div className={`drawer-wrapper ${entering ? " drawer-open" : null}`}>
      {title && (
        <div className="drawer-header">
          <H4 onClick={handleClick}>{title}</H4>
          <button onClick={() => handleDismiss()}>
            <img src={close} alt="close modal icon" />
          </button>
        </div>
      )}
      <div className="drawer-body" onClick={handleClick}>
        {props.children}
      </div>
    </div>
  );
};

export default Drawer;
