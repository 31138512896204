import React, { useState, useRef } from "react";
import Loading from "./Loading";
import "../styles/detail-section.scss";
import Modal from "../ui/src/components/Modal";
import Drawer from "../components/Drawer";
import { ReactComponent as Heart } from "../assets/heart_lg.svg";

// design system components
import { H5 } from "../ui/src/components/Typography";

import { ReactComponent as RecommendationIcon } from "../assets/recommendation_icon.svg";
import DiabetesRecommendationContent from "./modals/DiabetesRecommendationContent";
import { useTheme } from "../Theme";

const DetailSection = (props) => {
  const {
    loading,
    ready,
    header,
    fields,
    dismissRecommendation,
    hideRecommendations,
  } = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const drawerRef = useRef(false);
  const { theme } = useTheme();

  const hasRecommendation = fields?.find((field) => field.hasRecommendation);

  const recommendationsComplete = fields?.find(
    (field) => field.recommendationComplete
  );

  const errorColor = "#EA3F3F";
  const successColor = "#08A966";

  const selectTextColor = (field, type) => {
    if (field["should_review"] && !field["is_reviewed"]) {
      return errorColor;
    }
    if (field["is_reviewed"]) {
      return successColor;
    }
    return type === "LABEL" ? "#6C6E88" : "#333333";
  };

  return (
    <div className="detail-section">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsOpen(false)}
        title={"1 new care recommendation"}
        partial={true}
      >
        <DiabetesRecommendationContent setIsOpen={setIsOpen} />
      </Modal>
      <div class="header-row">
        <H5 className="header">{header}</H5>
        {loading && <Loading color={theme.primaryColor} />}
        {ready && hasRecommendation && !hideRecommendations && (
          <div className="recommendation" onClick={() => setIsOpen(true)}>
            <RecommendationIcon className="icon" fill={theme.primaryColor} />
            <H5 color={theme.primaryColor} bold={true}>
              1 recommendation
            </H5>
          </div>
        )}
      </div>
      {ready &&
        fields?.map((field, i) => {
          if (field.hasRecommendation) {
            drawerRef.current = true;
          }
          return (
            <div key={i} className="field-row">
              <H5 color={selectTextColor(field, "LABEL")}>{field.label}</H5>
              <H5 color={selectTextColor(field, "VALUE")}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: field.is_reviewed
                      ? field.review_value
                      : field.value,
                  }}
                ></span>
              </H5>
            </div>
          );
        })}
      {ready &&
        hasRecommendation &&
        !recommendationsComplete &&
        !hideRecommendations && (
          <Drawer
            title="1 new care recommendation"
            setModalIsOpen={setIsOpen}
            dismissDrawer={dismissRecommendation}
            dismissTimeout={6000}
            isOpen={modalIsOpen}
          >
            <div className="row">
              <Heart className="heart-lg" fill={theme.primaryColor} />
              <H5>
                We found recommendations that could improve your care.{" "}
                <span style={{ color: theme.primaryColor, fontWeight: "600" }}>
                  Click to learn more.
                </span>
              </H5>
            </div>
          </Drawer>
        )}
    </div>
  );
};

export default DetailSection;
