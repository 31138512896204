import React, { useState } from "react";
import arrowDown from "../assets/arrow_down.svg";
import arrowRight from "../assets/arrow_right.svg";
import { NO, REVIEW_BILLING, REVIEW_INSURANCE, YES } from "../constants";
import { Button } from "../ui/src/components";
import { H4, H5 } from "../ui/src/components/Typography";
import Card from "./Card";


const NON_EXPANDABLE_ITEMS = [REVIEW_BILLING, REVIEW_INSURANCE];

const RegistrationStepperCard = (props) => {
  const { item, setReadyToContinue, handleCardClickEvent, theme } = props;

  const yesOption = item.options.find(option => option.key === YES);
  const noOption = item.options.find(option => option.key === NO);
  const expandable = !NON_EXPANDABLE_ITEMS.includes(item.key);

  const [expanded, setExpanded] = useState(false);

  return (
    <Card className="review-item-card">
      <div className="title-row">
        <img src={item?.icon} alt="home icon" />
        <H4 bold={true}>{item?.title}</H4>
      </div>
      <H5>{item?.bodyText}</H5>
      <div className="button-row">
        {item?.options?.map((option) => (
          <Button
            key={option.label}
            variant="outlined"
            onClick={() => {
              setReadyToContinue(true);
              handleCardClickEvent(option.clickHandler, item, option);
            }}
            color={theme.primaryColor}
          >
            {option.label}
          </Button>
        ))}
      </div>
      {expandable && (
        <div className="more-details" onClick={() => setExpanded(prev => !prev)}>
          <H5 bold={true} color="#6C6E88">
            More details
          </H5>
          {expanded ? <img src={arrowDown} alt={"arrow down"} /> : <img src={arrowRight} alt="arrow right" />}
        </div>
      )}
      {expanded && expandable && (yesOption || noOption) &&(
        <div className="more-details-section">
          {noOption && (
            <div className="field-wrapper">
              <H5 bold color="#6C6E88">
                Current:
              </H5>
              <span
                className="h5"
                dangerouslySetInnerHTML={{
                  __html: noOption.value
                }}
              />
            </div>
          )}
          {yesOption && (
            <div className="field-wrapper">
              <H5 bold color="#08A966">
                Updated:
              </H5>
              <span
                className="h5"
                dangerouslySetInnerHTML={{
                  __html: yesOption.value
                }}
              />
            </div>
          )}
        </div>
      )}
    </Card>
  )
};

export default RegistrationStepperCard;