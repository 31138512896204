//libs
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//components
import RegistrationStepper from "../components/RegistrationStepper";
import Modal from "./../ui/src/components/Modal";
import { useInterval } from "../hooks";
import ReviewInsuranceModalContent from "../components/modals/ReviewInsuranceModalContent";
import ReviewBillingModalContent from "../components/modals/ReviewBillingModalContent";

// design system components
import { H2 } from "../ui/src/components/Typography";
import { Toast } from "../ui/src/components/Toast";

//styles
import "../styles/registration.scss";

//data
import { registrationDetails, sectionContentMap } from "../content";

import {
  PATIENT_DETAILS,
  REVIEW_BILLING,
  HANDLE_CONFIRM,
  OPEN_MODAL,
  DISABLED,
} from "../constants";

const Registration = (props) => {
  // derive page content from url param
  const location = useLocation();
  const [section, setSection] = useState(false);
  const [showStepper, setShowStepper] = useState(true);
  const [modalArgs, setModalArgs] = useState({});
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params?.get("section") || PATIENT_DETAILS;
    setSection(status.toUpperCase());
    remountStepper();
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1);
  }, [location.search]);

  const remountStepper = () => {
    setShowStepper(false);
    setTimeout(() => {
      setShowStepper(true);
    }, 100);
  };

  // interval to set steps for display
  const [stepMap, setStepMap] = useState({
    PATIENT_DETAILS: 0,
    CLINICAL_HISTORY: 0,
    INSURANCE_BILLING: 0,
    DOCUMENTS: 0,
  });
  useInterval(() => {
    if (stepMap[section] < registrationDetails[section]?.length) {
      setStepMap((prevState) => ({
        ...prevState,
        [section]: stepMap[section] + 1,
      }));
    }
  }, 1000);

  // page content
  const [details, setDetails] = useState(registrationDetails);

  // modal content
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  const handleUpdate = (y, x, value) => {
    setToastMessage(details[section][y].fields[x].toastMessage);
    setShowToast(true);
    setDetails((prevState) => {
      prevState[section][y].fields[x].isReviewed = true;
      prevState[section][y].fields[x].value = value;
      return { ...prevState };
    });
  };

  const handleCardClickEvent = (type, item, option) => {
    switch (type) {
      case HANDLE_CONFIRM:
        let args = {
          y: item?.indexY,
          x: item?.indexX,
          value: option?.value,
        };
        handleUpdate(...Object.values(args));
        break;
      case OPEN_MODAL:
        let _args = {
          y: item?.indexY,
          x: item?.indexX,
          value: option?.value,
        };
        setIsOpen(true);
        setActiveModal(item.key);
        setModalArgs(_args);
        break;
      case DISABLED:
        break;
      default:
        return;
    }
  };

  const dismissRecommendation = (sectionObj, y) => {
    for (let x = 0; x < sectionObj?.fields?.length; x++) {
      if (sectionObj.fields[x].hasRecommendation) {
        setDetails((prevState) => {
          prevState[section][y].fields[x].recommendationComplete = true;
          return { ...prevState };
        });
      }
    }
  };

  return (
    <>
      {showToast && (
        <Toast
          variant="info"
          message={toastMessage}
          className="custom-toast"
          onClose={() => setShowToast(false)}
        />
      )}

      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={() => setIsOpen(false)}
        title={activeModal === REVIEW_BILLING ? "Billing" : "Insurance Review"}
      >
        {activeModal === REVIEW_BILLING ? (
          <ReviewBillingModalContent
            closeModal={() => setIsOpen(false)}
            handleUpdate={handleUpdate}
            modalArgs={modalArgs}
          />
        ) : (
          <ReviewInsuranceModalContent
            closeModal={() => setIsOpen(false)}
            handleUpdate={handleUpdate}
            modalArgs={modalArgs}
          />
        )}
      </Modal>

      <div className="registration-page">
        <H2>Registering Samantha</H2>
        {section && showStepper && (
          <RegistrationStepper
            data={details[section]}
            step={stepMap[section]}
            sectionContent={sectionContentMap[section]}
            handleCardClickEvent={handleCardClickEvent}
            dismissRecommendation={dismissRecommendation}
            registrationDetails={details}
          />
        )}
      </div>
    </>
  );
};

export default Registration;
