export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateApptTime = () => {
  const hoursSet = ["9", "10", "11", "12", "1", "2", "3", "4", "5"];
  const minsSet = ["00", "15", "30", "45"];
  return (
    hoursSet[getRandomInt(0, hoursSet.length - 1)] +
    ":" +
    minsSet[getRandomInt(0, minsSet.length - 1)]
  );
};

export const monetize = (num) => {
  return `$${num?.toFixed(2)}`;
};
