import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

//components
import Card from "../components/Card";
import DetailSection from "../components/DetailSection";
import { useTheme } from "../Theme";
import Drawer from "./Drawer";
import RegistrationStepperCard from "./RegistrationStepperCard";

// design system components
import { H4, H5, H6 } from "../ui/src/components/Typography";
import { Button } from "../ui/src/components";

const RegistrationStepper = (props) => {
  const {
    data,
    step,
    sectionContent,
    handleCardClickEvent,
    dismissRecommendation,
    registrationDetails,
  } = props;

  const navigate = useNavigate();
  const { theme } = useTheme();

  const [readyToContinue, setReadyToContinue] = useState(false);
  const [showProgress, setShowProgress] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);

  const width = (step / data.length) * 100;

  const reviewItems = [];

  const selectBackgroundColor = () => {
    const primaryColor = theme.primaryColor;
    const warningColor = "#FAB247";
    const successColor = "#08A966";

    if (step < data.length) {
      return primaryColor;
    } else {
      if (reviewItems.length === 0) {
        return successColor;
      }
      return warningColor;
    }
  };

  data?.forEach((section, i) => {
    section.fields.forEach((field, j) => {
      if (field.shouldReview && !field.isReviewed) {
        if (step > i) {
          reviewItems.push({ ...field.reviewItem, indexY: i, indexX: j });
        }
      }
    });
  });

  const renderStepPhrase = (step) => {
    if (step === data.length) {
      if (reviewItems.length > 0) {
        return `Review ${reviewItems.length} item(s) to continue`;
      } else {
        return "Ready to continue!";
      }
    } else {
      return data[step]?.label;
    }
  };

  const ordinal = () => {
    if (!reviewItems) {
      return;
    }
    if (reviewItems.length === 1) {
      return "one";
    }
    if (reviewItems.length > 1) {
      return "many";
    }
  };

  // this function passes the registration details state to the next page
  const goToPage = (link) => {
    navigate(link, { state: { details: registrationDetails } });
  };

  const drawerOpen = step >= data.length && reviewItems.length === 0;

  return (
    <div className={`registration-wrapper ${drawerOpen ? "drawer-open" : ""}`}>
      {drawerOpen ? (
        <Drawer setModalIsOpen={setIsOpen} isOpen={modalIsOpen}>
          <div className="progress-drawer">
            <H5 bold={true}>Please review your information & continue</H5>
            <Button
              fullWidth={true}
              onClick={() => goToPage(sectionContent.link)}
              color={theme.primaryColor}
            >
              {sectionContent.buttonText}
            </Button>
          </div>
        </Drawer>
      ) : null}
      <H6 bold={true}>{renderStepPhrase(step)}</H6>
      {showProgress && (
        <div className="progress-bar-wrapper">
          <span
            style={{
              width: `${width}%`,
              backgroundColor: selectBackgroundColor(),
              transition: `width 300ms ease-in-out`,
            }}
            className={step < data.length ? "animated" : "inner"}
          />
        </div>
      )}
      {reviewItems?.length === 0 && !readyToContinue && (
        <Card className="zero-state-card">
          <img src={sectionContent.zeroStateImage} alt="no action items" />
          <H6 bold={true}>Checking for items to review...</H6>
        </Card>
      )}
      <div className={`card-wrapper ${ordinal()}`}>
        {reviewItems?.map((item, i) => (
          <RegistrationStepperCard
            key={i}
            item={item}
            setReadyToContinue={setReadyToContinue}
            handleCardClickEvent={handleCardClickEvent}
            theme={theme}
          />
        ))}
      </div>
      <H4 bold={true}>{sectionContent.title}</H4>
      <H5>{sectionContent.subtitle}</H5>
      {data?.map((section, i) => {
        return (
          <DetailSection
            loading={step === i}
            ready={step > i}
            header={section.header}
            fields={section.fields}
            dismissRecommendation={() => dismissRecommendation(section, i)}
          />
        );
      })}
      <Button
        fullWidth={true}
        disabled={step < data.length}
        color={theme.primaryColor}
        variant="outlined"
      >
        Edit my information
      </Button>
    </div>
  );
};

export default RegistrationStepper;
